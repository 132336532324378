import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import Categorycard from '../Categories/CategoryCard';

const Followcards = (props) => {
   return (<>
        <OwlCarousel className='category-cards-slider owl-theme'
            loop={false}
            margin={15}
            nav={false}
            dots={false}
            responsive={{
                0: {
                    items: 1,
                    stagePadding: 20
                },
                768: {
                    items: 2,
                    stagePadding: 30
                },
                992: {
                    items: 2,
                    stagePadding: 50
                },
                1200: {
                    margin: 30,
                    items: 3,
                    stagePadding: 0,
                    touchDrag: false,
                    mouseDrag: false
                }
            }}>
            
            
                    <Categorycard
                    postDetails={props.data}
                    class={"item"}
                    
                    />
                
            
        </OwlCarousel>
    </>);
}

export default Followcards;