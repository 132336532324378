import Cookies from 'js-cookie'
import { User } from '../sdk/user';
export let Storage={
    setCookie:(data)=>{
    Cookies.set(data.key,data.value,{expires: data.expires_at/(24*60*60),path: '/',})
    },
    getCookie:(key)=>{
        let cookie=Cookies.get(key);
        if(cookie !==undefined && cookie !=="" && cookie!==null){
            return cookie;
        }
        else{
            return false;
        }
    },
    removeCookie:(key)=>{
        Cookies.remove(key);
    },
    setLocalStorage:(data)=>{
        localStorage.setItem(data.key,data.value);
    },
    removeLocalStorage:(key)=>{
        localStorage.removeItem(key);
    },
    getlocalstorage: async function (key,auth=false){
        let local=window.localStorage.getItem(key);
        if(local !==undefined && local !=="" && local !==null){
          if(!auth){
            return new Promise((res,rej)=>{
                res(JSON.parse(local));
            });
          }
          try{
            return new Promise(async(res,rej)=>{
               let data= await new User().getUserData();
               let obj={
                    id:data._id,
                    resources:data.resources,
                    firstName:data.firstName,
                    lastName:data.lastName,
                    email:data.email,
                    userType:data.userType,
                    username:data.username,
                    emailVerified:data.emailVerified,
                    profile_pic:data.profile_pic,
                    cover_pic:data.cover_pic,
                    ispassword:data.ispassword,
                    about:data.about,
                    website_url:data.website_url,
                    notificationSettings:data.notificationSettings
                }
                
                res({context:obj,redux:{
                    intersts:data.intersts,
                    posts:data.posts,
                    followers:data.followers,
                    followings:data.followings,
                    collections:data.collections,
                    blockUsers:data.blockUsers,
                    id:data._id,
                    resources:data.resources
                }});
            })
          }catch(e){
              
              return new Promise((res,rej)=>{
                  res(false);
              })
        }
    }
        else{
            if(this.getCookie()){
                try{
                  return new Promise(async(res,rej)=>{
                    let data= await new User().getUserData();
                    let obj={
                    firstName:data.firstName,
                    lastName:data.lastName,
                    email:data.email,
                    username:data.username,
                    emailVerified:data.emailVerified,
                    profile_pic:data.profile_pic,
                    intersts:data.intersts,
                    posts:data.posts,
                    followers:data.followers,
                    followings:data.followings,
                    collections:data.collections,
                    blockUsers:data.blockUsers
                }
                res(obj);
                  })
                }catch(e){
                    
                    return new Promise((res,rej)=>{
                        res(false);
                    })

                }
            }else{
                
                return new Promise((res,rej)=>{
                    res(false);
                })
            }
            
        }
    },
    

}