import Multiselect from 'multiselect-react-dropdown';
import { useState, useEffect } from 'react';
import { useAuthContext } from '../../context';
import { useUpdateProfile } from '../../customHooks';
import { selector } from '../../redux';
import { Post } from '../../sdk';
import { toasterFunction } from '../DefaultComponents';
import { Modal } from '../../helpers';
export const Collection = ({ postId }) => {
    const userAuth = useAuthContext();
    const user = selector(state => state.ownUser);


    const [updateProfile] = useUpdateProfile();
    let userCollection = user.data.collections;
    let selectedValues = [];
    let collectionsIds = [];
    if (userCollection.length) {
        for (let val of userCollection) {
            if (val.posts.includes(postId)) {
                selectedValues.push(val);
                collectionsIds.push(val._id);
            }
        }
    }
    const [state, setState] = useState({
        postId: "",
        collectionIds: [],
        selectItem: [],
    });
    useEffect(() => {
        setState((pre) => {
            return {
                ...pre, postId: postId,
                collectionIds: collectionsIds,
                selectItem: selectedValues
            }
        })
    }, [postId])
    const addToCollection = async () => {
        try {
            let result = await new Post().addToCollectionOrResource(state);
            //updateProfile(result);
            toasterFunction.success("post has been added in collection");
            Modal.hideTab(".modal");

        } catch (e) {
            toasterFunction.error("error Occur ! Please Try Again")
        }
    }
    if (postId) {
        return (<>
            <div className="modal fade join_form_popup" id="selectCollection" tabIndex="-1" aria-labelledby="joinCommunityLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="joinus_form">
                            <div className="modal-header justify-content-center border-0">
                                <h2 className="modal-title" id="joinCommunityLabel">Select Collections
                                </h2>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="post-field-collect row justify-content-center align-items-end">
                                    <div className="col-md-9">
                                        <label htmlFor="postCollections">Collections</label>
                                        <Multiselect
                                            displayValue="name"
                                            customCloseIcon={<><i className='fal fa-times'></i></>}
                                            onKeyPressFn={function noRefCheck() { }}
                                            onRemove={function noRefCheck(value, select) {
                                                let selectItem = state.selectItem.filter((val) => {
                                                    return val._id !== select._id
                                                })
                                                let collectionIds = state.collectionIds.filter((val) => {
                                                    return val !== select._id;
                                                })
                                                setState((pre) => {
                                                    return { ...pre, selectItem: selectItem, collectionIds: collectionIds }
                                                })

                                            }}
                                            onSearch={function noRefCheck() { }}
                                            onSelect={function noRefCheck(value, select) {
                                                console.log(state)
                                                state.selectItem.push(select);
                                                state.collectionIds.push(select._id);
                                                console.log(state)
                                                setState((pre) => {
                                                    return { ...pre, ...state }
                                                })
                                            }}
                                            selectedValues={state.selectItem}
                                            placeholder={"select Collections"}
                                            options={userCollection}
                                            showCheckbox
                                            avoidHighlightFirstOption={true}
                                        />
                                    </div>
                                    {/* <div className="col-md-3">
                <button className='btn site-btn' type='button'  id="#createCollection" onClick={(e,id="#createCollection")=>{
                Modal.hideTab(".modal");
                  Modal.showTab(id);
                }}><span>Create New Collection</span></button>
            </div> */}
                                </div>
                            </div>


                        </div>
                        <div className="modal-footer justify-content-center align-items-center border-0">
                            <div className="col-md-9">

                                <button type="submit" className="btn site-btn" onClick={addToCollection} disabled={state.buttonDisable}><span>Continue  {(state.loader) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span></button>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
        )
    }
    else {
        return (<>
            <div style={{ display: "none" }}>loading..</div>
        </>)


    }

}