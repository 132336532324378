import { Storage } from "../helpers";
import {axios} from "./config"
export class Categories extends axios{
   axios;
   token;
   prefix;
   constructor(){
   super();
   this.axios=this.axiosConfig();
   this.token=Storage.getCookie(`${process.env.NODE_ENV}-userToken`);
   this.prefix="/categories/"
    }
   async getCategories(){
        try{
           let result=await this.axios({
            method:"get",
            url:this.prefix.concat("getCategories"),
            headers:{
            Authorization:this.token
            }
            });
            return new Promise((res,rej)=>{
            res(result.data);
            })
        }catch(e){
            return new Promise((res,rej)=>{
                rej(e);
            })
        }
       
        }
}