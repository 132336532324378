import React from 'react';

const MediaOutput = (props) => {
  
   return (<>
    {props.data.map((val,index)=>{
    return (<>
      <div className="col-auto output_img" style={{backgroundColor:props.selected.includes(val)?"#0000006b":"#e3e3e3",cursor:"pointer"}} onClick={()=>props.selectImage(val)}>
            {/* <button className='btn p-0 lh-1' type="button" onClick={()=>props.deleteFun(index,props.type)}><i className='fal fa-times'  ></i></button> */}
            {props.selected.includes(val) && <><img src='assets/images/check-mark-29114_1280.webp' style={{position:"absolute"}}/></>}
            <img src={val} alt={val} />
        </div>
        
      </>)
    })}
    
    
   {
       (props.otherType!=="blog"&& props.mediaLoader && <> <div class="spinner-border uploader_spinner" style={{width:"3rem",height:"3rem"}} role="status">
       <span class="visually-hidden">Loading...</span>
     </div></>)
   }
   
   {props.otherType !=="blog"&& props.errors.mediaUrls.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                           })}
    
   
        
    </>);
}

export default MediaOutput;