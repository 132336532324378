import Routers from './Routes/Routers';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Popups from './Components/Popups';
import { useCheckAuth } from './customHooks';
import { useEffect, useState } from 'react';
import Header from './Components/Header';
import { useAuthContext } from './context';
import { Modal } from './helpers';
import { ToasterComponent } from './Components/DefaultComponents';
import { useLocation } from 'react-router-dom'
import Footer from './Components/Footer';
function App() {
const [checkAuth]=useCheckAuth();
const userAuth=useAuthContext();
const location=useLocation();
const checkEmailVerfication=()=>{
  if(userAuth.isAuthenticated && !userAuth.user.emailVerified){
    Modal.showTab("#successPopup");
  }
}
useEffect(()=>{
  let cronJob= setInterval(() => {
    checkAuth();
    checkEmailVerfication()
   },3000); 
   
  return ()=>clearTimeout(cronJob)
},[checkAuth]);

  return (<>
   {location.pathname !=="/coming-soon" && <Header  key={new Date()} login={userAuth.isAuthenticated} />}
    
    <Routers />
    <Popups />
    {location.pathname ==="/coming-soon" || location.pathname ==="/contact" ? null : <Footer />}
    {location.pathname ==="/contact" ? <Footer changeLogo={true} /> : null}
    <ToasterComponent />
    
    
    
    
    
    
  </>);
}

export default App;