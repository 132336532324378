import React, { useState,useEffect,useCallback } from 'react';
import FilterItem from '../Filters/FilterItem';
import Followcards from './FollowCards';
import ProfileImgUploader from './ProfileImgUploader';
import { useQuery,useUpdateProfile } from '../../customHooks';
import { Loader, toasterFunction } from '../DefaultComponents';
import { User,Auth,Post } from '../../sdk';
import { Validate } from '../../helpers';
import { useAuthContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import { dispatch as parentDisptach, selector } from '../../redux';
import { OwnUserUpdateByKey } from '../../redux/Actions';


const CompleteProfile = () => {
    const userAuth=useAuthContext();
   
    const ownUser=selector(state=>state.ownUser)
    const categories=selector(state=>state.categories)
    const userIntersts=()=>{
        let arr= ownUser.data.intersts;
         let interstIds=arr.map((val)=>{
          return val._id
          
          })
      return interstIds
  }
    const navigate=useNavigate();
    let obj={
        profileStep1:true,
        profileStep2:false,
        profileStep3:false,
    }
    
    if(sessionStorage.getItem("profile")){
       obj=JSON.parse(sessionStorage.getItem("profile"));
    }
    const [state,setState]=useState({
        profileTab:obj,
        formData:{
         profileStep1:{
             profile_pic:"",
             showProflePic:"assets/images/profile_silhotte.svg",
             about:userAuth.user.about,
             website_url:userAuth.user.website_url,
         },
         profileStep2:{
            userIntersts:userIntersts()
         },
         profileStep3:{
             followers:[]
         }
        },
        formDataError:{
        profileStep1:{
            profile_pic:[],
            about:[],
            website_url:[],
            other:[]
        }
        },
        activeTab:1,
        loader:false,
        error:false,
        buttonLoader:false,
        errorText:"",
        interests:categories.data,
        buttonDisable:false,
        interstPost:[],
        interstPostError:"",
    })
    useEffect(()=>{
setState((pre)=>{
    return {...pre,interests:categories.data}
})
    },[categories])
    const dispatch=parentDisptach();
    const [updateProfile]=useUpdateProfile();
    const param=useQuery();
    let token=param.get("token");
    let userId=param.get("userId");
    const verifyEmail=async ()=>{
        try{
            let result=  await new Auth().verifyEmail({token:token,userId:userId});
            toasterFunction.success("Account has been verfied");
            updateProfile(result);
            setState((pre)=>{
                return {...pre,loader:false}
            })
            }catch(error){
            let errorText=""
            if(error.response.status===409){
               errorText="you are not logged in with relevant Account"
            }
            else{
                errorText="link may be expired or not authorize !Please Try again"
            }
            setState((pre)=>{
                return {...pre,errorText:errorText,error:false}
            })
            toasterFunction.error(errorText);
        }
      
    }
    useEffect(()=>{
     if(token && userId){
           verifyEmail();
        }
        else{
            setState((pre)=>{
                return {...pre,loader:false}
            })
        }
    },[])
    
    const toggleStep=useCallback( async (currentTab,actionNumber,ownTab=false)=>{
       
        
     if(!ownTab){
        let oldProfileState={profileStep1:false,profileStep2:false,profileStep3:false};
        let activeTab=Number(currentTab)+Number(actionNumber);
        oldProfileState[`profileStep${activeTab}`]=true;
        if(activeTab===1){
            setState((pre)=>{
                return {...pre,profileTab:oldProfileState,activeTab:activeTab,loader:false}
            })
        }
        else if(activeTab===2){
            let newProfile2State={...state.formData.profileStep2};
            let newState={...state.formData,profileStep2:newProfile2State}
            setState((pre)=>{
                return {...pre,profileTab:oldProfileState,activeTab:activeTab,loader:false,formData:newState}
                })
           }
            else{
                
                try{
                    setState((pre)=>{
                        return {...pre,loader:true}
                    })
                 let interstPosts=await new Post().getUserInterstPost();
                 setState((pre)=>{
                    return {...pre,profileTab:oldProfileState,activeTab:activeTab,loader:false,interstPost:interstPosts}
                })
                }catch(e){
                    setState((pre)=>{
                        return {...pre,profileTab:oldProfileState,activeTab:activeTab,loader:false,interstPostError:e.response.data.message}
                    })
                }
               
            }
         }  
     else{
     
         let obj={
            profileStep1:false,
            profileStep2:false,
            profileStep3:false,
         }
         obj[`profileStep${Number(currentTab)}`]=true;
         if(currentTab===2){
           setState((pre)=>{
            
            return {...pre,profileTab:obj,activeTab:currentTab,loader:false}
        })
            
           
         }
         else if(currentTab===3){
            try{
                setState((pre)=>{
                    return {...pre,loader:true}
                })
               
                let interstPosts=await new Post().getUserInterstPost();
                setState((pre)=>{
                    return {...pre,profileTab:obj,activeTab:currentTab,loader:false,interstPost:interstPosts}
                })
               }catch(e){
                   setState((pre)=>{
                       return {...pre,profileTab:obj,activeTab:currentTab,loader:false,interstPostError:e.response.data.message}
                   })
               }
           
         }
         else{
            setState((pre)=>{
                return {...pre,profileTab:obj,activeTab:currentTab,loader:false}
            })
         }
         
    } 
    },[])
    const onHandler=(event)=>{
    let key=event.target.name;
    let value=event.target.value;
    let oldState=state.formData.profileStep1;
    if(key==="profile_pic"){
        if(event.target.files[0].size > 5*(1024*1024)){
            let errors={
                profile_pic:["File Size not greater than 5 mb"],
                about:[],
                website_url:[]
            };
            let newState={...state.formDataError,profileStep1:errors}
           setState((pre)=>{
               return{...pre,formDataError:newState}
           })
           return false;
        }
        oldState={...oldState,showProflePic:URL.createObjectURL(event.target.files[0]),profile_pic:event.target.files[0]}
    }
    else{
        oldState={...oldState,[key]:value}
    }
    
    let newState={...state.formData,profileStep1:oldState}
     setState((pre)=>{
        return {...pre,formData:newState}
    })

    }
    const onSubmit = async (e) => {
        e.preventDefault();
        try{
            setState((pre)=>{
                return {...pre,buttonLoader:true,buttonDisable:true}
               })
            if(state.profileTab.profileStep1){
            if(Object.values(state.formData.profileStep1).length > 0){
                let valid=await Validate.updateProfileOnCompletion.validate(state.formData.profileStep1,{abortEarly: false});
                let result=await new User().updateProfile(valid);
                sessionStorage.setItem("profile",JSON.stringify({profileStep1:false,profileStep2:true,profileStep3:false}))
                toasterFunction.success("Profile has been updated");
                updateProfile(result);
                toggleStep(1,1)
            }
            }
            else if(state.profileTab.profileStep2){
                let result=await new User().interestToggle(state.formData.profileStep2.userIntersts);
                sessionStorage.setItem("profile",JSON.stringify({profileStep1:false,profileStep2:false,profileStep3:true}))
                toasterFunction.success("Profile has been updated");
                dispatch(OwnUserUpdateByKey("intersts",result.intersts,"push"))
                toggleStep(2,1)
                
}
            setState((pre)=>{
                return {...pre,buttonLoader:false,buttonDisable:false}
               })
        }catch(e){
            let obj={
            profile_pic:[],
            about:[],
            website_url:[],
            other:[]
            }
            if(e.errors && e.errors.length > 0){
                for(let error of e.errors){
                obj[Object.keys(error)[0]].push(Object.values(error)[0]);
             }
            }
            else if(e.response){
            let keys=Object.keys(e.response.data);
            obj[keys[0]]=e.response.data[keys[0]]
            }
            else{
                obj.other.push("Error Occur! Please Contact your Adminstration");
            }
            let newState={...state.formDataError,profileStep1:obj};
            setState((pre)=>{
             return {...pre,formDataError:newState,buttonLoader:false,buttonDisable:false}
            })
          
        }
        
    }
    
if(!state.loader){
   if(!state.error){
    return (<>
        <section className='complete_profile'>
        <div className="container">
            <div className="wrapper">
                {/* Section Title */}
                <div className="section-title text-md-center">
                    <h2>Complete Your Profile</h2>
                </div>
                {/* Tabbing Section */}
                <div className="profile_tabbing">
                    <ul className="nav nav-tabs justify-content-center main" id="profileTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link active ${state.profileTab.profileStep1 ? 'font-bold' : ''}`} id="aboutU_Btn" data-bs-toggle="tab" data-bs-target="#aboutU_Tab" type="button" role="tab" aria-controls="aboutU_Tab" aria-selected="true" onClick={()=>toggleStep(1,0,true)}>
                                About You
                                <svg xmlns="http://www.w3.org/2000/svg" width="108" height="8" viewBox="0 0 108 8"><path d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" fill="" /></svg>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${state.profileTab.profileStep2 ? 'active font-bold' : state.profileTab.profileStep3 ? 'active' : ''}`} onClick={()=>toggleStep(2,0,true)}>
                                Your Interest
                                <svg xmlns="http://www.w3.org/2000/svg" width="216" height="8" viewBox="0 0 216 8">
                                    <g id="Group_1392" data-name="Group 1392" transform="translate(-811 -269)">
                                        <path id="Fill_1_Copy" data-name="Fill 1 Copy" d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" transform="translate(811 269)" fill="" />
                                        <path id="Fill_1_Copy_2" className='d-none d-md-block' data-name="Fill 1 Copy 2" d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" transform="translate(919 269)" fill="" />
                                    </g>
                                </svg>

                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${state.profileTab.profileStep3 ? 'active font-bold' : ''}`} onClick={()=>toggleStep(3,0,true)}>
                                You Should Follow
                                <svg xmlns="http://www.w3.org/2000/svg" width="216" height="8" viewBox="0 0 216 8">
                                    <g id="Group_1392" data-name="Group 1392" transform="translate(-811 -269)">
                                        <path id="Fill_1_Copy" data-name="Fill 1 Copy" d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" transform="translate(811 269)" fill="" />
                                        <path id="Fill_1_Copy_2" data-name="Fill 1 Copy 2" d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" transform="translate(919 269)" fill="" />
                                    </g>
                                </svg>

                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="profileTabContent">
                    {state.profileTab.profileStep1 &&  <form onSubmit={onSubmit}>
                           
                           <div className={`tab-pane fade show active`} id="aboutU_Tab" role="tabpanel" aria-labelledby="aboutU_Btn">
                               <div className="tab-wrapper">
                                   {/* Profile Img Uploader */}
                                   <ProfileImgUploader imgSrc="assets/images/profile_silhotte.svg" value={state.formData.profileStep1} setValue={onHandler} error={state.formDataError.profileStep1.profile_pic}/>
                                   {/* Tell Us ABout Yourself */}
                                   <div className="tell-us">
                                       <label htmlFor="tellAboutYou" className="d-flex justify-content-between align-items-center mb-3">
                                           <span>Tell Us About You</span>
                                           <span className='limit'>Max 500 Character</span>
                                       </label>
                                       <textarea className="form-control" name="about" id="tellAboutYou" value={state.formData.profileStep1.about} maxLength={500} placeholder='Lead UI / UX Designer, ☕️Tea Addictive , 🛫Traveler, 🎵Music, 29th July My Day, Leo™' rows={7} onChange={onHandler}>{state.formData.profileStep1.about}</textarea>
                                       {state.formDataError.profileStep1.about.map((val,index)=>{
                                            return (<>
                                            <p className='form_error'>{val}</p> 
                                            </>)
                                          })}
                                   </div>
                                   <div className="btn-container">
                                       <div className='row justify-content-between align-items-end mt-4'>
                                           <div className="col-md-6 mb-3 mb-md-0">
                                               <div>
                                                   <label htmlFor="webOrBlogLink" className="form-label">Do You Have Any Website or Blog?</label>
                                                   <input type="text" name="website_url" className="form-control" id="webOrBlogLink" placeholder="Enter website" value={state.formData.profileStep1.website_url} onChange={onHandler}/>
                                                   {state.formDataError.profileStep1.website_url.map((val,index)=>{
                                            return (<>
                                            <p className='form_error'>{val}</p> 
                                            </>)
                                          })}
                                               </div>
                                           </div>
                                           <div className="col-md-6">
                                               <ul className="nav nav-tabs d-block" id="profileTab" role="tablist">
                                                   <li className="nav-item" role="presentation">
                                                       <button className='btn site-btn w-100'   id="interest_Btn" data-bs-toggle="tab" data-bs-target="#interest_Tab" type="submit" role="tab" aria-controls="interest_Tab" aria-selected="false" disabled={state.buttonDisable}><span>I am Excited, Take me to Next Step {(state.buttonLoader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></button>
                                                   </li>
                                               </ul>
                                           </div>
                                           <div className="col-12 mt-4">
                                               <div className="row">
                                                   <div className="col-auto ms-auto">
                                                       <button className='btn skip-btn' onClick={()=>toggleStep(1,1)} data-bs-toggle="tab" data-bs-target="#interest_Tab" type="button" role="tab" aria-controls="interest_Tab" aria-selected="false">Skip for now</button>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                           </form>}
                           {state.profileTab.profileStep2 &&  <form onSubmit={onSubmit}>
                            <div className={`tab-pane fade show `} id="interest_Tab" role="tabpanel" aria-labelledby="interest_Btn">
                                <h3 className='mb-4 text-center'>Tell Us What are Your Goals</h3>
                                <div className="tab-wrapper">
                                    {/* Interest row */}
                                    {(Object.keys(state.interests)).length > 0?
                                     state.interests.map((val)=>{
                                       return (<>
                                    <div className="profile_interest_row" key={val.id}>
                                    <h3>{val.name}</h3>
                                    <div className="row">
                                    
                                    {(Object.keys(val.subCategories)).length > 0 ? 
                                    
                                    val.subCategories.map((val2)=>{
                                    return (
                                        <>
                                    <div className="col-3">    
                                    <FilterItem
                                        key={val2._id}
                                        
                                        data={val2}
                                        checked={(state.formData.profileStep2.userIntersts.includes(val2._id))}
                                        state={[state,setState]}
                                        tabIndex={2}
                                        />
                                        </div>
                                        </>
                                        )
                                    })
                                    
                                    :""
                                    
                                    
                                    
                                    }
                                   
                                       
                                        
                                    </div>
                                </div>
                                </>)
                                   })
                                :<>
                                <p>there is no category yet, Please skip this step</p>
                                </>}
                                   
                                    {/* Buttons */}
                                    <div className="btn-container">
                                        <div className='row justify-content-center align-items-end mt-5'>
                                        {(Object.keys(state.interests)).length > 0 &&  <div className="col-md-6">
                                                <ul className="nav nav-tabs d-block" id="profileTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className='btn site-btn w-100' disabled={state.buttonDisable} id="suggest_Btn" type="submit" ><span>I am Excited, Take me to Next Step{(state.buttonLoader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></button>
                                                    </li>
                                                </ul>
                                            </div>}
                                           
                                            <div className="col-12 mt-4">
                                                <div className="row">
                                                    <div className="col-auto me-auto">
                                                        <button className='btn skip-btn' type="button" onClick={()=>toggleStep(2,-1)}>Go Back</button>
                                                    </div>
                                                    <div className="col-auto ms-auto">
                                                        <button className='btn skip-btn'  onClick={()=>toggleStep(2,1)} data-bs-toggle="tab" data-bs-target="#suggest_Tab" type="button" role="tab" aria-controls="suggest_Tab" aria-selected="false">Skip for now</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </form>}
                       
                           {state.profileTab.profileStep3 &&  <form>
                            <div className={`tab-pane fade show `} id="suggest_Tab" role="tabpanel" aria-labelledby="suggest_Btn">
                                <h3 className='text-center mb-5'>Based on Your Profile We Recommend You to Follow These People</h3>
                                <div className="follow_suggestions">
                                    {/* Carousel For Category Cards */}
                                    <Followcards data={state.interstPost}/>
                                    
                                </div>
                                {/* Buttons */}
                                <div className="btn-container">
                                    <div className='row justify-content-center align-items-end mt-5'>
                                        <div className="col-md-4">
                                            <button className='btn site-btn w-100' type="button" onClick={()=>{
                                                 navigate("/home");
                                            }}><span>You are Done</span></button>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="row">
                                                <div className="col-auto me-auto">
                                                    <button className='btn skip-btn' onClick={()=>toggleStep(3,-1)}>Go Back</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>}
                           
                    </div>
                   
                    {/* <!-- Sucess popup --> */}

                </div>
            </div>
        </div>
    </section>
</>)
   }
   else{
       return (<>
       <div>{state.errorText}</div>
       </>)
   }
    }
    else{
        return (<>
        <Loader/>
        </>)
    }
       
      }
    


export default CompleteProfile;