import { toasterFunction } from "../Components/DefaultComponents";
import { useAuthContext } from "../context";
import { Storage,Encryption, Modal } from "../helpers";


export const useAuthentication=(data=false)=>{
const userAuth=useAuthContext();
const afterAuthentication=(data=false)=>{
if(data && typeof(data)==="object"){
        let obj={
            firstName:data.userData.firstName,
            lastName:data.userData.lastName,
            email:data.userData.email,
            username:data.userData.username,
            emailVerified:data.userData.emailVerified,
            profile_pic:data.userData.profile_pic,
            about:data.userData.about,
            website_url:data.userData.website_url,
            cover_pic:data.userData.cover_pic,
            ispassword:data.userData.ispassword,
            userType:data.userData.userType,
            notificationSettings:data.userData.notificationSettings
        }
        Storage.setCookie({
            key:`${process.env.NODE_ENV}-userToken`,
            value:Encryption.encryptBase64(data.tokenInfo.token),
            expires_at:data.tokenInfo.expirayTime

        })  
       Storage.setLocalStorage({
       key:`${process.env.NODE_ENV}-user`,
       value:JSON.stringify(obj)
       });
       obj={...obj,
        id:data.userData._id,
        }
      
        let reduxData={
            intersts:data.userData.intersts,
            posts:data.userData.posts,
            followers:data.userData.followers,
            followings:data.userData.followings,
            collections:data.userData.collections,
            blockUsers:data.userData.blockUsers,
            resources:data.userData.resources
           }
      
       userAuth.connectToSocket(obj.id);
       userAuth.updateState(obj,false,reduxData);
       Modal.hideTab(".modal");
       toasterFunction.success("login successfully");
       return data; 
    }
}
const logoutUser=()=>{
Storage.removeCookie(`${process.env.NODE_ENV}-userToken`);
Storage.removeLocalStorage(`${process.env.NODE_ENV}-user`);
userAuth.updateState(false);
toasterFunction.success("logout successfully");
}
return [afterAuthentication,logoutUser];
}