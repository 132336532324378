import React, { useState, useEffect, memo } from 'react';
import { Post, Categories } from '../../sdk';
import ImgUrlField from './ImgUrlField';
import MediaOutput from './MediaOutput';
import PostFields from './PostFields';
import UploaderField from './UploaderField';
import { Loader, toasterFunction } from '../DefaultComponents';
import { Modal, Validate } from '../../helpers';
import VideoOutput from './VideoOutput';
import { OwnUserUpdateByKey } from '../../redux/Actions';
import { dispatch as parentDispatch } from '../../redux';
import { useNavigate } from 'react-router-dom';
import { object } from 'yup';

const EditPost = (props) => {
    let stateData={
        
        formData:{
            postType: "image",
            mediaGetting: "default",
            visibiltyMode: "private",
            title: "",
            description: "",
            mediaGettingUrl: "",
            mediaUrls: [],
            tags: [],
            seoTags: [],
            categories: [],
            videos: [],
            images: [],
            collection: false,
            collections: [],
            resource: false,
            resources: []
        },
        errors: {
            postType: [],
            mediaGetting: [],
            visibiltyMode: [],
            title: [],
            description: [],
            mediaGettingUrl: [],
            mediaUrls: [],
            tags: [],
            seoTags: [],
            categories: [],
            collections: [],
            resources: [],
            other: []
        },
        loader: false,
        buttonDisable: false,
        imageGettingUrl: "",
        imagesUrl: [],
        videoGettingUrl: "",
        blogGettingUrl: "",
        videosUrl: [],
        preLoader: true,
        categories: [],
        userTags: [],
        mediaLoader: false,
        mediainputDisable: false,
        descriptionLength:500
    }
    
    
    const [state, setState] = useState(stateData)
    const dispatch = parentDispatch();
    const navigate=useNavigate();
    const checkVideoDuration=(file)=>{
        let flag=true;
        var vid = document.createElement('video');
        var fileURL = URL.createObjectURL(file);
        vid.src = fileURL;
        vid.ondurationchange = function() {
            console.log(this.duration)
          if(this.duration > 180){
            flag=false;
          }
        };
        return flag;
    }
    const uploadMedia = async (media, key) => {

        try {
            setState((pre) => {
                return {
                    ...pre, loader: true, buttonDisable: true, mediaLoader: true,
                    mediainputDisable: true,
                }
            })
            let size = 5;
            if (key === "video") {
                size = 50
            }
            let error = false;
            for (let file of media) {
                if (file.size > size * ((1024 * 1024))) {
                    error = true;
                   

                    alert(`${key} size should be lesser than ${size}mb`)
                    break;

                }
                
                if(key === "video" && checkVideoDuration(file)){
                    error = true;
                    alert("video should be maximum 3 mints");
                    break;
                }
            }
            if (!error) {
                let result = await new Post().uploadMedia(media, key);
                if (key === "image") {
                    if (state.imagesUrl.length < 5) {
                        //state.imagesUrl=[...state.imagesUrl,...result.data];
                        //state.formData.mediaUrls=[...state.formData.mediaUrls,...result.data];
                        state.formData.mediaUrls = result.data[0];
                        state.imagesUrl = [result.data[0]];
                    }
                    else {
                        state.imagesUrl = [result.data[0]];
                        state.formData.mediaUrls = [result.data[0]]
                    }

                }
                else {
                    if (state.imagesUrl.length < 5) {
                        state.formData.mediaUrls = result.data[0];
                        state.videosUrl = [result.data[0]];
                    }
                    else {
                        state.videosUrl = [result.data[0]];
                        state.formData.mediaUrls = [result.data[0]]
                    }

                }
                setState((pre) => {
                    return {
                        ...pre, ...state, loader: false, buttonDisable: false, mediaLoader: false,
                        mediainputDisable: false,
                    };
                })
            }
            else {
                setState((pre) => {
                    return {
                        ...pre, ...state, loader: false, buttonDisable: false, mediaLoader: false,
                        mediainputDisable: false,
                    };
                })
            }


        } catch (e) {

        }
    }
    useEffect(() => {
        localStorage.removeItem("modal");
        let appendData=stateData.formData;
        if(Object.keys(props.details).length){
        appendData={...appendData,...props.details,post_id:props.details._id,collection:props.details.collections.length?true:false,resource:props.details.resources.length?true:false}    
        }
        new Categories().getCategories().then((data) => {
        setState((pre) => {
                return { ...pre,formData:appendData,categories: data, preLoader: false ,videosUrl:appendData.postType==="video" && appendData.media?[appendData.media[0].media_url]:[],imagesUrl:(appendData.media && appendData.media.length) ? [appendData.media[0].media_url]:[]};
            })
        }).catch((e) => {
            setState((pre) => {
                return { ...pre,formData:{...props.details},preLoader: false };
            })
        })
    }, [props])
    const uploadMediaFromUrl = async (url, key) => {
        try {

            if (state.formData.mediaUrls.length >= 5) {
                toasterFunction.error("Limit has been exceed, please remove some media");
                return false;
            }
            let type;
            if (key === "imageGettingUrl") {
                state.imageGettingUrl = url
                type = "img"
                state.formData.mediaGettingUrl = url
                setState((pre) => {
                    return {
                        ...pre, ...state, loader: true, buttonDisable: true, mediaLoader: true,
                        mediainputDisable: true
                    }
                })
            }
            else if (key === "blogGettingUrl") {
                state.blogGettingUrl = url
                type = "blog"
                state.formData.mediaGettingUrl = url
                setState((pre) => {
                    return {
                        ...pre, ...state, loader: true, buttonDisable: true, mediaLoader: true,
                        mediainputDisable: true
                    }
                })
            }
            else {
                type = "video"
                state.videoGettingUrl = url
                state.formData.mediaGettingUrl = url
                setState((pre) => {
                    return {
                        ...pre, ...state, loader: true, buttonDisable: true, mediaLoader: true,
                        mediainputDisable: true
                    }
                })
            }
            let result = await new Post().mediaFromUrl({ url: url, key: type });
            let count=0;
            if (key === "imageGettingUrl") {
                let totalAllow = 5;
                let currentAvailabe = totalAllow - state.formData.mediaGettingUrl.length;
               
                if (result[0].length) {
                    for (let value of result[0]) {
                        if (currentAvailabe !== 0) {
                            state.imagesUrl.push(value);
                            if(count===0){
                            state.formData.mediaUrls.push(value)
                            }
                            count ++;
                        }
                        else {
                            break;
                        }
                        currentAvailabe--;
                    }
                }
                else {
                    toasterFunction.error("image not found");
                }

            }
            else if (key === "blogGettingUrl") {
                let totalAllow = 5;
                let currentAvailabe = totalAllow - state.formData.mediaGettingUrl.length;
                if (result.length) {
                    
                        if (result[0].type === "image") {
                            state.imagesUrl=result[0].src;
                            state.formData.mediaUrls.push(result[0].src[0])
                            }
                        else {
                            state.videosUrl.push(result[0].src);
                            state.formData.mediaUrls.push(result[0].src)
                        }
                        state.formData.title=result[0].title;
                        state.formData.description=result[0].description;

                }

                else {
                    toasterFunction.error("media not found");
                }
            }
            else {
                let totalAllow = 5;
                let currentAvailabe = totalAllow - state.formData.mediaGettingUrl.length;
                if (result[0].length) {

                    for (let value of result[0]) {
                        if (currentAvailabe !== 0) {
                            state.videosUrl.push(value);
                            state.formData.mediaUrls.push(value)
                        }
                        else {
                            break;
                        }
                        currentAvailabe--;
                    }
                }
                else {
                    toasterFunction.error("video not found");
                }

            }

            setState((pre) => {
                return {
                    ...pre, ...state, loader: false, buttonDisable: false, mediaLoader: false,
                    mediainputDisable: false
                };
            })



        } catch (e) {
            toasterFunction.error(e.response.data.message);
            setState((pre) => {
                return {
                    ...pre, ...state, loader: false, buttonDisable: false, mediaLoader: false,
                    mediainputDisable: false
                };
            })
        }

    }
    const deletemedia = (key, mediaType) => {
        if (mediaType === "image") {
            let newArr = state.imagesUrl.filter((val, index) => {
                return index !== key
            })
            state.imagesUrl = newArr;
            state.formData.mediaUrls = newArr;
        }
        else {
            let newArr = state.videosUrl.filter((val, index) => {
                return index !== key
            })
            state.videosUrl = newArr;
            state.formData.videosUrls = newArr;
        }
        setState((pre) => {
            return { ...pre, ...state };
        })
    }
    const searchFromUrl=(key)=>{
        let urlRegex = new RegExp(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/);
        if (urlRegex.test(state.formData.mediaGettingUrl)) {
            uploadMediaFromUrl(state.formData.mediaGettingUrl, key);
        }
        else {
            toasterFunction.error("url is not valid");

        }
    }
    const onHandler = (event) => {
        try {
            let key = event.target.name;
            let value = event.target.value;
            if (key === "video" || key === "image") {
                uploadMedia(event.target.files, key);
            }
            else if (key === "videoGettingUrl" || key === "imageGettingUrl" || key === "blogGettingUrl") {
               state[key]=value;
               state.formData.mediaGettingUrl=value;
               setState((pre) => {
                   return { ...pre, ...state }
               })
            }
            else if (key === "resource" || key === "collection") {
                if (state.formData[key]) {
                    state.formData[key] = false;
                }
                else {
                    state.formData[key] = true;
                }

                setState((pre) => {
                    return { ...pre, ...state }
                })
            }
            else if (key === "visibiltyMode") {
                if (state.formData.visibiltyMode === "public") {
                    state.formData.visibiltyMode = "private"
                }
                else {
                    state.formData.visibiltyMode = "public"
                }
                setState((pre) => {
                    return { ...pre, ...state }
                })
            }
            else if (key === "postType") {
                state.formData[key] = value;
                state.formData.mediaUrls = [];
                state.formData.mediaGettingUrl = ""
                setState((pre) => {
                    return {
                        ...pre, ...state, mediaGettingUrl: "",
                        mediaUrls: [], imagesUrl: [], videosUrl: [], imageGettingUrl: "",
                        videoGettingUrl: "",descriptionLength:value==="blog"?5000:500
                    }
                })
            }
            else {
                state.formData[key] = value;
                setState((pre) => {
                    return { ...pre, ...state }
                })
            }


        } catch (e) {

        }
    }
    const setSeoTag = (e) => {
        if(e.length <= 6){
            state.formData.seoTags = e;
            setState((pre) => {
                return { ...pre, ...state }
            })
        }
        else{
            alert("only 6 tags is allow")
        }
       
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setState((pre) => {
                return { ...pre, buttonLoader: true, buttonDisable: true }
            })
            await Validate.createPost.validate({...state.formData,mediaUrls:[state.formData.mediaUrls]}, { abortEarly: false });
            let result = await new Post().editPost({...state.formData,mediaUrls:state.formData.mediaUrls.length?[state.formData.mediaUrls]:[]});
            dispatch(OwnUserUpdateByKey("posts", result, "update"))
            toasterFunction.success("post edit successfully");
            Modal.hideTab("#editPostPopup");
            navigate(`/postdetails/${result._id}`);
            setState({
                formData: {
                    postType: "image",
                    mediaGetting: "default",
                    visibiltyMode: "private",
                    title: "",
                    description: "",
                    mediaGettingUrl: "",
                    mediaUrls: [],
                    tags: [],
                    seoTags: [],
                    categories: [],
                    videos: [],
                    images: [],
                    collection: false,
                    collections: [],
                    resource: false,
                    resources: []
                },
                errors: {
                    postType: [],
                    mediaGetting: [],
                    visibiltyMode: [],
                    title: [],
                    description: [],
                    mediaGettingUrl: [],
                    mediaUrls: [],
                    tags: [],
                    seoTags: [],
                    categories: [],
                    collections: [],
                    resources: [],
                    other: []
                },
                loader: false,
                buttonDisable: false,
                imageGettingUrl: "",
                imagesUrl: [],
                videoGettingUrl: "",
                blogGettingUrl: "",
                videosUrl: [],
                preLoader: false,
                categories: state.categories,
                userTags: [],
                mediaLoader: false,
                mediainputDisable: false,
                descriptionLength:500
            })

        } catch (e) {
            console.log(e)
            let obj = {
                postType: [],
                mediaGetting: [],
                visibiltyMode: [],
                title: [],
                description: [],
                mediaGettingUrl: [],
                mediaUrls: [],
                tags: [],
                categories: [],
                other: [],
                collections: [],
                resources: [],
            }

            if (e.errors && e.errors.length > 0) {
                for (let error of e.errors) {
                    obj[Object.keys(error)[0]].push(Object.values(error)[0]);
                }

            }
            else if (e.response) {
                let keys = Object.keys(e.response.data);
                obj[keys[0]] = e.response.data[keys[0]]
            }
            else {
                obj.other.push("Error Occur! Please Contact your Adminstration");
            }



            setState((pre) => {
                return { ...pre, errors: obj, buttonLoader: false, buttonDisable: false }
            })

        }

    }
 const selectImage=(url)=>{
    let oldState={...state}
    oldState.formData.mediaUrls[0]=url;
    setState((pre)=>{
        return {...pre,oldState}
    })
 }
 
    if (!state.preLoader) {
        return (<>
            <div className="modal fade addPost_popup" id="editPostpopup" tabIndex="-1" aria-labelledby="addPostPopupLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content addPost_content">
                        <div className="modal-header justify-content-center border-0 p-0">
                            <h2 className="modal-title" id="addPostPopupLabel">Edit Post</h2>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {/* Navigation */}
                        <div className="nav nav-tabs justify-content-center border-0" id="addPost-tab" role="tablist">
                            <button className={`nav-link border-0 ${(state.formData.postType === "video") ? "active" : ""}`} id="nav-video-tab" type='button' name='postType' value="video" onClick={onHandler}>
                                Video
                                <svg xmlns="http://www.w3.org/2000/svg" width="98" height="8" viewBox="0 0 108 8">
                                    <path id="Fill_1_Copy_2" data-name="Fill 1 Copy 2" d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" fill="#8b78ef" />
                                </svg>
                            </button>
                            <button className={`nav-link border-0 ${(state.formData.postType === "image") ? "active" : ""}`} id="nav-img-tab" name='postType' value="image" onClick={onHandler}>
                                Images
                                <svg xmlns="http://www.w3.org/2000/svg" width="108" height="8" viewBox="0 0 108 8">
                                    <path id="Fill_1_Copy_2" data-name="Fill 1 Copy 2" d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" fill="#8b78ef" />
                                </svg>
                            </button>
                            <button className={`nav-link border-0 ${(state.formData.postType === "blog") ? "active" : ""}`} id="nav-blog-tab" name='postType' value="blog" onClick={onHandler}>
                                Blog
                                <svg xmlns="http://www.w3.org/2000/svg" width="84" height="8" viewBox="0 0 108 8">
                                    <path id="Fill_1_Copy_2" data-name="Fill 1 Copy 2" d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" fill="#8b78ef" />
                                </svg>
                            </button>
                        </div>
                        {/* Content */}
                        <form onSubmit={onSubmit}>
                            <div className="tab-content" id="nav-tabContent">
                                {state.formData.postType !== "blog" &&
                                    <div className="tab-pane fade show active">
                                        <div className="upload_options d-flex">
                                            <div className="form-check me-3">
                                                <input className="form-check-input shadow-none" type="radio" id="addVideo" name="mediaGetting" value="default" onChange={onHandler} defaultChecked={(state.formData.mediaGetting === "default")} required />
                                                <label className="form-check-label" htmlFor="addVideo"><img src="assets/images/addImgIcon.svg" alt="Upload Img Icon" /> Add {state.formData.postType}</label>
                                            </div>

                                            <div className="form-check ms-3">
                                                <input className="form-check-input shadow-none" type="radio" id="urlVideo" name="mediaGetting" value="fromUrl" onChange={onHandler} defaultChecked={(state.formData.mediaGetting === "fromUrl")} required />
                                                <label className="form-check-label" htmlFor="urlVideo"><i className="far fa-globe-americas"></i> Add {state.formData.postType} from URL</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {state.formData.postType === "blog" &&
                                    <div className="tab-pane fade show active">
                                        <div className="upload_options d-flex">
                                            <div className="form-check me-3">
                                                <input className="form-check-input shadow-none" type="radio" id="addVideo" name="mediaGetting" value="default" onChange={onHandler} defaultChecked={(state.formData.mediaGetting === "default")} required />
                                                <label className="form-check-label" htmlFor="addVideo"><img src="assets/images/addImgIcon.svg" alt="Upload Img Icon" /> Default</label>
                                            </div>
                                            <div className="form-check ms-3">
                                                <input className="form-check-input shadow-none" type="radio" id="urlBlog" name="mediaGetting" value="fromUrl" onChange={onHandler}
                                                    defaultChecked={(state.formData.mediaGetting === "fromUrl")} required />
                                                <label className="form-check-label" htmlFor="urlBlog"><i className="far fa-globe-americas"></i> Add {state.formData.postType} from URL</label>
                                            </div>
                                        </div>
                                    </div>
                                }


                                {state.formData.postType === "video" && <div className={`tab-pane fade show active`} id="nav-video" >
                                    <div className="uploader_output">
                                        {(state.formData.mediaGetting !== "default") && <ImgUrlField errors={state.errors} disabled={state.mediainputDisable} name="videoGettingUrl" value={state.videoGettingUrl} fun={onHandler} searchFromUrl={searchFromUrl} buttonDisable={state.buttonDisable}/>}
                                        <div className="d-flex flex-wrap">
                                            {((state.formData.mediaGetting === "default" && state.formData.mediaUrls.length < 5) && <UploaderField disabled={state.mediainputDisable} error={state.videoError} name="video" fun={onHandler} acceptAttr='video/*' />)}
                                            <VideoOutput errors={state.errors} mediaLoader={state.mediaLoader} type="video" deleteFun={deletemedia} data={state.videosUrl} imgSrc='assets/images/output_placeholder.png' />

                                        </div>
                                    </div>
                                    <PostFields errors={state.errors} data={{ state: state, onHandler: onHandler, parentStateFun: setState,setSeoTag }} />
                                </div>}
                                {state.formData.postType === "image" && <div className={`tab-pane fade show active`} id="nav-images" >
                                    <div className="uploader_output">
                                        {(state.formData.mediaGetting !== "default") && <ImgUrlField errors={state.errors} disabled={state.mediainputDisable} name="imageGettingUrl" value={state.imageGettingUrl} fun={onHandler} searchFromUrl={searchFromUrl} buttonDisable={state.buttonDisable}/>}

                                        <div className="d-flex flex-wrap">
                                            {((state.formData.mediaGetting === "default" && state.formData.mediaUrls.length < 5) && <UploaderField disabled={state.mediainputDisable} error={state.imageError} name="image" fun={onHandler} acceptAttr='image/*' />)}

                                            <MediaOutput errors={state.errors} mediaLoader={state.mediaLoader} type="image" deleteFun={deletemedia} selected={state.formData.mediaUrls} selectImage={selectImage} data={state.imagesUrl} imgSrc='assets/images/output_placeholder.png' />

                                        </div>
                                    </div>
                                    <PostFields errors={state.errors} data={{ state: state, onHandler: onHandler, parentStateFun: setState,setSeoTag }} />
                                </div>}
                                {state.formData.postType === "blog" && <div className={`tab-pane fade show active`} id="nav-blog" >
                                    <div className="uploader_output">
                                        {(state.formData.mediaGetting !== "default") && <ImgUrlField errors={state.errors} disabled={state.mediainputDisable} name="blogGettingUrl" value={state.blogGettingUrl} fun={onHandler} searchFromUrl={searchFromUrl} buttonDisable={state.buttonDisable}/>}
                                        {!!state.imagesUrl.length && <div className="d-flex flex-wrap">
                                            <MediaOutput errors={state.errors} mediaLoader={state.mediaLoader} type="image" selected={state.formData.mediaUrls} otherType="blog" deleteFun={deletemedia} selectImage={selectImage} data={state.imagesUrl} imgSrc='assets/images/output_placeholder.png' />
                                        </div>
                                        }
                                        {!!state.videosUrl.length && <div className="d-flex flex-wrap">
                                            <VideoOutput errors={state.errors} mediaLoader={state.mediaLoader} type="video" otherType="blog" deleteFun={deletemedia} data={state.videosUrl} imgSrc='assets/images/output_placeholder.png' />
                                        </div>
                                        }
                                        {
                                            (state.mediaLoader && <> <div class="spinner-border uploader_spinner" style={{ width: "3rem", height: "3rem" }} role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div></>)
                                        }

                                        {state.errors.mediaUrls.map((val, index) => {
                                            return (<>
                                                <p className='form_error'>{val}</p>
                                            </>)
                                        })}

                                    </div>
                                    <PostFields errors={state.errors} data={{ state: state, onHandler: onHandler, parentStateFun: setState, setSeoTag }} />
                                </div>}

                                <div className="post_now_btn mt-5">
                                    <button type='submit' className='btn site-btn' style={{ width: '100%', maxWidth: '400px' }} disabled={state.buttonDisable}><span>I Would Like to Post Now  {(state.buttonLoader) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>);
    }
    else {
        return (<>
            <Loader />
        </>)

    }

}

export default memo(EditPost);