import { Categories_Post } from "../ActionConstants"
let intialState={
    loading:true,
    data:{
        filterPost:[],
        filterItems:[],
        bannerName:"Empowering Women Worldwide",
        userCatSelectArr:[],
        userSubCatSelectArr:[],
        limit:4
    },
    error:null,
   
}
export const getCategoriesPostReducer=(state=intialState,{type,payload})=>{
    
switch (type) {
    case Categories_Post.success:
    return {...state,data:payload,loading:false}
    case Categories_Post.error:
    return {...state,error:payload,loading:false,data:[]}
    default:
    return state
        
}
}