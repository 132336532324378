import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

export const CategorySkeleton=(props)=>{
return (<>

<Skeleton />



</>)
}
