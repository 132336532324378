import { useAuthContext } from "../context";
import { Storage} from "../helpers";
export const useCheckAuth=()=>{
const userAuth=useAuthContext();
const checkAuth=()=>{
    let getCookie=Storage.getCookie(`${process.env.NODE_ENV}-userToken`);
    
    if(!getCookie && userAuth.isAuthenticated){
        userAuth.updateState(false);
        Storage.removeLocalStorage(`${process.env.NODE_ENV}-user`);
    }
    
}
return [checkAuth];
}