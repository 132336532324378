import BannerSection from './Filters/BannerSection';
import CategoryContainer from './Categories/CategoryContainer';
import { useQuery } from '../customHooks';
import { Loader } from './DefaultComponents';
import { useEffect, useState,memo} from 'react';
import { Auth } from '../sdk';
import { NewPassword } from './popup';
import { useAuthContext } from '../context';
import { selector,dispatch as parentDispatch } from '../redux';
import { getCategoriesPostThunk } from '../redux/Thunk';
const Landing = () => {
    const userAuth=useAuthContext();
    const param=useQuery();
    const dispatch=parentDispatch();
    let token=param.get("token");
    let userId=param.get("userId");
    const [state,setState]=useState({
        loader:(token)?true:false,
        error:false,
        errorText:"",
        resetPopUp:false,
    })
   
    const categories=selector(state=>state.categories);
    
    useEffect(()=>{
        if(token && userId){
         new Auth().forgotPasswordVerify({token:token,userId:userId}).then((data)=>{
            setState((pre)=>{
                return {...pre,loader:false,resetPopUp:true}
            })
         }).catch((error)=>{
             let errorText=""
             errorText="link may be expired or not authorize !Please Try again"
             setState((pre)=>{
               return {...pre,errorText:errorText,error:true,loader:false}
           })
          
         })
        }
        
    },[])
    const onHandler=(categoryId=false,subCatid=false,limit=4)=>{
        window.$('html, body').animate({scrollTop: '0px'}, 300)
        dispatch(getCategoriesPostThunk(categoryId,subCatid,limit))
        return false
           }
   
   
    if(!state.loader){
        if(!state.error){
            return (<>
              {(state.resetPopUp)?<NewPassword userId={userId} parentState={setState}/>:""}
               
                <BannerSection />
                {/* <!-- Categories Section --> */}
                <section className="categories">
                    <div className="container">
                        
                   
                        <CategoryContainer
                            categoryTitle='Business'
                            categoryLink='/categories'
                           
                        />
                       
                    </div>
                    {/* <!-- Start about brnad --> */}
                    {!userAuth.isAuthenticated &&  <section className="about-brand">
                        <div className="container">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-lg-4 col-md-6">
                                    <div className="brand-col d-flex align-items-center">
                                        <img src="assets/images/vector-1.svg" alt="" /> Something Heading here about Your Growth Stop videos
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="brand-col d-flex align-items-center">
                                        <img src="assets/images/vector-2.svg" alt="" /> Something Heading here about Your Growth Stop videos
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="brand-col d-flex align-items-center">
                                        <img src="assets/images/vector-3.svg" alt="" /> Something Heading here about Your Growth Stop videos
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    }
                   
                 
                </section>
                {/* <!-- Category Topics --> */}
                <section className="category-topics">
                    <div className="container">
                        <div className="section-title">
                            <h2>Featured topics by category</h2>
                        </div>
                        <div className="row">
                        {!categories.loading&& categories.data.map((val)=>{
                                         return (<>
                                        <div className="col-md-3">
                                <div className="cat-topic-col" onClick={()=>onHandler(val._id,false,100)} style={{cursor:"pointer"}}>
                                <h3>{val.name}</h3>
                                <ul>
                                {val.subCategories.map((val2)=>{
                                             return <li key={val2._id} onClick={()=>onHandler(val2.category_id,val2._id,100)} style={{cursor:"pointer"}}><a>{val2.name}</a></li>
                                            })}
                                
                                    
                                        
                                        
                                    </ul>
                                </div>
                            </div>
                                           
                                           
                                           
                                        
                                         
                                         </>)
                                    })}
                            
                          
                        </div>
                    </div>
                    
                </section>
               
                {!userAuth.isAuthenticated && <section className="join-us">
                    <div className="container">
                        {/* <!-- Row 1 --> */}
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 img">
                                <div className="join-us-col">
                                    <img src="assets/images/join-feature-img.png" alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="join-us-col">
                                    <div className="section-title">
                                        <h2>Join us as a Copmany</h2>
                                        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                                        <button className="btn site-btn" data-bs-toggle="modal" data-bs-target="#joinCommunity"><span>Sign Up</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Trusted Logos --> */}
                        <div className="trusted-partners">
                            <div className="section-title text-md-center">
                                <h2>Trusted by companies of all sizes</h2>
                            </div>
                            <div className="row justify-content-between align-items-center">
                                <div className="col-md-3">
                                    <img src="assets/images/partner-logo-1.png" alt="" />
                                </div>
                                <div className="col-md-3">
                                    <img src="assets/images/partner-logo-2.png" alt="" />
                                </div>
                                <div className="col-md-3">
                                    <img src="assets/images/partner-logo-3.png" alt="" />
                                </div>
                                <div className="col-md-3">
                                    <img src="assets/images/partner-logo-4.png" alt="" />
                                </div>
                                <div className="col-md-3">
                                    <img src="assets/images/partner-logo-5.png" alt="" />
                                </div>
                            </div>
                        </div>
                        {/* <!-- Row 2 --> */}
                        <div className="row justify-content-between align-items-center flex-row-reverse">
                            <div className="col-md-6 img">
                                <div className="join-us-col">
                                    <img src="assets/images/about-inspo.png" alt="" className="ms-0 me-auto" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="join-us-col">
                                    <div className="section-title">
                                        <h2>About Your Growth Stop</h2>
                                        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                                        <button className="btn site-btn" data-bs-toggle="modal" data-bs-target="#joinCommunity"><span>Sign Up</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
               
                {/* <!-- Join Us Section --> */}
                
            </>);

    }
    else{
        return (<>
            <div>{state.errorText}</div>
            </>)
    }
 }
 else{
    return (<>
        <Loader />
        </>)
 }
   
}

export default memo(Landing);