import React,{ createContext, useContext,useEffect,useState } from "react";
import { Loader } from "../Components/DefaultComponents";
import { Storage } from "../helpers";
import { Notification } from "../sdk";
import socket from "socket.io-client"
import { dispatch as parentDispatch } from "../redux";
import { OwnUserUpdate } from "../redux/Actions";

export const AuthContext = createContext({
    user: null,
    isAuthenticated: false,
    socket:"",
    notification:[],
    updateState: () => {},
    updatebyKey:()=>{},
    connectToSocket:()=>{},
    getAllNotification:()=>{},
   
})
export const useAuthContext = () => {
    return useContext(AuthContext)
}
const clientSocket=socket(process.env.REACT_APP_Socket_Url);
export const AuthContextProvider= (props)=>{
    let [state,setState]=useState({
    user: null,
    isAuthenticated: false,
    loader:true,
    componentLoader:false,
    notification:[],
});

const dispatch=parentDispatch();
const connectToSocket=(id)=>{
    clientSocket.emit("joinRoom",id);
    clientSocket.on("joinedRoom",(data)=>{
        console.log(data)
    })
    clientSocket.on("getNotifcation",async (data)=>{
        console.log(data);
        getUserData();

    })
}

const getAllNotification=async ()=>{
try{
    let notification=await new Notification().getAllNotification();
    setState((pre)=>{
        return {...pre,notification:notification}
    })
}catch(e){
    setState((pre)=>{
        return {...pre,notification:[]}
    })
}
}

const updatebyKey=(data,key,add="add")=>{
    let oldValues=state.user[key];
   
    if(add==="add" ){
        oldValues.push(data);
     state.user[key]=oldValues;
        
        
    }
    else if(add==="update"){
        state.user[key]=oldValues.map((val)=>{
        if(val._id===data._id){
            return data;
        }
        else{
            return val;
        }
    })
    
    }
    else{
        state.user[key]=oldValues.filter((val)=>{
            return val._id !==data.id
        })
       
    }
    
    setState((pre)=>{
        return {...pre,...state}
    })

}

const updateState=async (user=false,notification=false,reduxData={})=>{
if(user){
   if(Object.keys(reduxData).length > 0){
    dispatch(OwnUserUpdate(reduxData)); 
     }
      if(notification){
        let notification=await new Notification().getAllNotification();
      
       setState((pre)=>{
            return {...pre,notification:notification,user:user,isAuthenticated: true}
        })
      }
      else{
        setState((pre)=>{
            return{...pre, user: user,isAuthenticated: true} 
}) 
      }
        
   }
    else{
        
        setState((pre)=>{
                    return{...pre, user: null,isAuthenticated: false,notification:[]} 
        })
        dispatch(OwnUserUpdate(user))
    }
}
const getUserData=async()=>{
    try{
        let userData=await Storage.getlocalstorage(`${process.env.NODE_ENV}-user`,true);
        let obj=state;
        if(userData){
         connectToSocket(userData.redux.id);
         try{
            let notification=await new Notification().getAllNotification();
            obj={...obj,notification:notification}
           }catch(e){
            obj={...obj,notification:[]}
           }
           
        dispatch(OwnUserUpdate(userData.redux));  
       
        obj={...obj,user: userData.context,isAuthenticated: true,loader:false}
        }
        else{
            obj={...obj,user: null,isAuthenticated: false,loader:false}
        }
        
       
      
        setState((pre)=>{
            return {...pre,...obj};
        })
        }catch(e){
            
            setState((pre)=>{
                return {...pre,user: null,isAuthenticated: false,loader:false};
            })
    }
}

useEffect(()=>{
getUserData();
},[])
if(!state.loader){
const context={
        user: state.user,
        isAuthenticated: state.isAuthenticated,
        socket:clientSocket,
        notification:state.notification,
        updateState:updateState,
        updatebyKey:updatebyKey,
        connectToSocket:connectToSocket,
        getAllNotification:getAllNotification,
        
        
    }
    return (
        <>
        <AuthContext.Provider value={context}>
        {props.children}
        </AuthContext.Provider>
        </>
    )
}
else{
    return (
    <>
   <Loader/>
    </>
    )
}

}