import { Storage } from "../helpers";
import {axios} from "./config"
export class Notification extends axios{
   axios;
   token;
   prefix;
   constructor(){
   super();
   this.axios=this.axiosConfig();
   this.token=Storage.getCookie(`${process.env.NODE_ENV}-userToken`);
   this.prefix="/notification/"
    }
    async getAllNotification(){
        try{
            let result=await this.axios({
                method:"post",
                url:this.prefix.concat("getAllNotification"),
                headers:{
                Authorization:this.token
                }
                });
                return new Promise((res,rej)=>{
                res(result.data);
                })
            
           
            }catch(e){
            return new Promise((res,rej)=>{
            rej(e);
            })
            }
       
        }
        async readAllNotification(){
            try{
                let result=await this.axios({
                    method:"post",
                    url:this.prefix.concat("readAllNotification"),
                    headers:{
                    Authorization:this.token
                    }
                    });
                    return new Promise((res,rej)=>{
                    res(result.data);
                    })
                
               
                }catch(e){
                return new Promise((res,rej)=>{
                rej(e);
                })
                }
           
            }   
            async readNotification(id){
                try{
                    let result=await this.axios({
                        method:"post",
                        url:this.prefix.concat("readNotification"),
                        data:{
                            id:id
                        },
                        headers:{
                        Authorization:this.token
                        }
                        });
                        return new Promise((res,rej)=>{
                        res(result.data);
                        })
                    
                   
                    }catch(e){
                    return new Promise((res,rej)=>{
                    rej(e);
                    })
                    }
               
                }   
    }



