import React from 'react';

const FilterItem = (props) => {
    const [state,setState]=props.state;

     const onHandler=(event)=>{
     let tabIndex=`profileStep${props.tabIndex}`;
     let value=event.target.value;
     let userIntersts=state.formData[tabIndex].userIntersts;
    if(userIntersts.includes(value)){
         userIntersts=userIntersts.filter((val)=>{
             return val !==value;
         })
     }
     else{
         userIntersts.push(value);
     }
     let newState={...state.formData[tabIndex],userIntersts:userIntersts};
     newState={...state.formData,[tabIndex]:newState}
      setState((pre)=>{
          return {...pre,formData:newState}
      })
     }
     
    return (<>
        <div className="filter_item">
            <input type="checkbox" checked={props.checked} onChange={onHandler} value={props.data._id}/>
            <label htmlFor={props.data.id}>
                <img src="assets/images/check_mark.svg" alt="Check Mark" />
                <span>
                    {props.data.name}
                </span>
            </label>
        </div>
    </>);
}

export default FilterItem;