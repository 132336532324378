import React,{useState} from 'react';
import { useAuthContext } from '../../context';
import { Modal,Validate } from '../../helpers';
import { Collection, Resource } from '../../sdk';
import { toasterFunction } from '../DefaultComponents';
import ProfileCollectionCard from './ProfileCollectionCard';
import { OwnUserUpdateByKey } from '../../redux/Actions';
import { dispatch as parentDispatch } from '../../redux';

const CollectionMain = (props) => {
    let key;
    const userAuth = useAuthContext();
    const dispatch=parentDispatch();
    const [state,setState]=useState({
        name: "",
        description: "",
        img_url: {},
        img_urlShow: "",
        _id:"",
        buttonDisable:false,
        buttonLoader:false,
        collectionFormHandling:{
            errors: {
                name: [],
                description: [],
                img_url: [],
                other: []
            }
        }
    })
    const updateCollection=(id)=>{
        let data=props.data.find(val=>val._id===id);
        if(data){
        setState(pre=>{
        return {...pre,...data,img_urlShow:data.img_url,img_url:""}
        });
        Modal.showTab("#updateCollection");
        }
        else{
            alert("not found")
        }
        
        }
        const onHandler = (event) => {
            let key = event.target.name;
            let value = event.target.value;
            if (key === "img_url") {
                if (event.target.files[0].size > 5 * (1024 * 1024)) {
                   
                    alert("image should be maximum 5 mb")
                    return false;
                }
                else {
                    state.img_url = event.target.files[0];
                    state.img_urlShow = URL.createObjectURL(event.target.files[0]);
                    setState((pre) => {
                        return { ...pre, ...state };
                    })
    
                }
            }
            else {
                state[key] = value;
                setState((pre) => {
                    return { ...pre, ...state };
                })
            }
        }
        const onCollectionUpdate = async (e) => {
            e.preventDefault();
            try {
                setState((pre) => {
                    return { ...pre, buttonLoader: true, buttonDisable: true }
                })
                let valid = await Validate.createCollection.validate(state, { abortEarly: false });
                let result = await new Collection().updateCollection({...valid,collection_id:state._id});
                dispatch(OwnUserUpdateByKey("collections",result,"update"));
                toasterFunction.success("Collection updated successfully");
                Modal.hideTab("#updateCollection");
               
                
                setState((pre) => {
                    return { ...pre, buttonLoader: false, buttonDisable: false }
                })
            } catch (e) {
                console.log(e)
                let obj = {
                    name: [],
                    description: [],
                    img_url: [],
                    other: []
                }
                console.log(e.errors)
                if (e.errors && e.errors.length > 0) {
                    for (let error of e.errors) {
                        if (typeof (error) === "string") {
                            obj["password"].push(error);
    
                        }
                        else {
                            obj[Object.keys(error)[0]].push(Object.values(error)[0]);
    
                        }
    
                    }
    
                }
                else if (e.response) {
                    let keys = Object.keys(e.response.data);
                    obj[keys[0]] = e.response.data[keys[0]]
                }
                else {
                    obj.other.push("Error Occur! Please Contact your Adminstration");
                }
                state.collectionFormHandling.errors = obj;
                setState((pre) => {
                    return { ...pre, ...state, buttonDisabled: false, buttonLoader: false };
                })
    
            }
    
        }
    const selectDetails = async (id, lock = false) => {
        try {
            let result;
            if (lock) {
                alert("this resource is locked");
            }
            if (props.type === "collections") {
                result = await new Collection().getCollection(id);
                let posts=[];
                if(result.length){
                    posts=result[0].posts;
                }
                console.log(posts)
                props.tabToggle(5,-1,posts);
            }
            else {

                result = await new Resource().getResource(id);

            }
            key = props.type;
            props.parentState((pre) => {
                return { ...pre, id: id, types: props.type, [key]: result }
            })
        } catch (e) {
            console.log(e);
            props.parentState((pre) => {
                return { ...pre, id: id, type: props.type, [props.type]: [] }
            })
            toasterFunction.error("Error! please try again")
        }
    }
    if (props.type === "resourcesItems") {
        
        return (<>
            {props.data.filter(val=>{
                         const regex = new RegExp(props.searchinput, 'i');
                         const name = `${val.title}`;
                        
                         return regex.test(name);
                            
                        }).map((val) => {
                return (
                    <div className="col-lg-4 col-md-6" key={val.id}>


                        <div className="profile_collection_card">
                            <button className='btn d-block' style={{ cursor: 'pointer' }} onClick={() => props.linkUrl(props.id, props.lock)}>
                                {(val.resourceType === 'video') ? <img src={val.media[0]} alt="Collection Feature Img" className='collection_img' style={{ maxHeight: "184px" }} /> :
                                    (val.resourceType === "pdf") ? <img src='assets/images/pdf.png' alt='' className='doc_img' style={{ maxHeight: "220px" }} /> :
                                        <img src='assets/images/docx.png' alt='' className='doc_img img_word' style={{ maxHeight: "220px" }} />}
                            </button>
                        </div>

                    </div>
                );
            })}
        </>);
    }
    else {
        
        return (<>
         <div class="modal fade collect-resource" id="updateCollection" tabindex="-1" aria-labelledby="createCollectionLabel" aria-hidden="true">
            <form onSubmit={onCollectionUpdate}>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header justify-content-center border-0">
                            <h5 class="modal-title" id="createCollectionLabel">Update Collection</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <div className="profile_img_uploader mt-4">
                                <div className="uploader d-inline-block position-relative  mt-4">
                                    <img src={state.img_urlShow} alt="Profile Img" className='profile' />
                                    <button className='btn p-0 shadow-none'>
                                        <input className='uploader_input' type="file" name="img_url" id="ProfileImgUploader" accept='image/*' onChange={onHandler}  />
                                        <img src="assets/images/uploader_icon.svg" alt="Img Uploadern Icon" />
                                       
                                    </button>
                                  
                                </div>
                                {state.collectionFormHandling.errors.img_url.map((val, index) => {
                                            return (<>
                                                <p className='form_error'>{val}</p>
                                            </>)
                                        })}

                            </div>

                            <div class="post-field-title">
                                <label htmlFor="collectionTitle">Collection Name</label>
                                <input type="text" name="name" id="collectionTitle" value={state.name} onChange={onHandler} className="form-control" placeholder="Any Text Here" required />
                                {state.collectionFormHandling.errors.name.map((val, index) => {
                                    return (<>
                                        <p className='form_error'>{val}</p>
                                    </>)
                                })}
                            </div>

                            <div class="post-field-title mb-4">
                                <label for="collectionDescription">Collection Description</label>
                                <textarea name="description" id="collectionDescription" class="form-control" rows="9" value={state.description} onChange={onHandler} placeholder="Dorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua pt enim ad minim" spellCheck="false" required></textarea>
                                {state.collectionFormHandling.errors.description.map((val, index) => {
                                    return (<>
                                        <p className='form_error'>{val}</p>
                                    </>)
                                })}
                            </div>

                        </div>
                        <div class="modal-footer border-0 justify-content-between">
                            <button type="button" class="btn close_btn shadow-none" data-bs-dismiss="modal" aria-label="Close"><i className='far fa-arrow-left'></i> Back to Collections</button>
                            <button className='btn site-btn' style={{ maxWidth: '300px', width: '100%', padding: '12px 12px' }} type="submit" disabled={state.buttonDisable}>
                                <span>Update Collection{(state.buttonLoader) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
            {props.data.filter(val=>{
                         const regex = new RegExp(props.searchinput, 'i');
                         const name = `${val.name}`;
                        
                         return regex.test(name);
                            
                        }).map((val) => {
                
                return (
                    <div className={props.description ? "col-12" : "col-lg-4 col-md-6"} key={val.id}>
                    {/* {console.log(val.name)} */}
                        {(props.type === "resources") &&
                            <>
                                {(val.premium === 1) ? <ProfileCollectionCard
                                    linkUrl={selectDetails}
                                    imgSrc={val.img_url}
                                    collectionName={val.name}
                                    detailPage={props.description}
                                    collectionDescription={val.description}
                                    id={val._id}
                                    postAuthor={`${val.user.firstName} ${val.user.lastName}`}
                                    premium={(val.premium === 1) ? true : false}
                                    postPrice={val.ammount}
                                    lock={true}
                                    follow={val.followers.includes(userAuth.user.id)}
                                    userId={val.user_id}
                                    onProfile={props.onProfile}
                                    followers={val.followers.length}
                                /> : <>
                                    <ProfileCollectionCard
                                        linkUrl={selectDetails}
                                        imgSrc={val.img_url}
                                        collectionName={val.name}
                                        detailPage={props.description}
                                        collectionDescription={val.description}
                                        postAuthor={`${val.user.firstName} ${val.user.lastName}`}
                                        id={val._id}
                                        lock={false}
                                        follow={val.followers.includes(userAuth.user.id)}
                                        userId={val.user_id}
                                        onProfile={props.onProfile}
                                        followers={val.followers.length}
                                    />
                                </>}

                            </>

                        }
                        {(props.type === "collections") &&
                            <ProfileCollectionCard
                                linkUrl={selectDetails}
                                imgSrc={val.img_url}
                                collectionName={val.name}
                                id={val._id}
                                lock={false}
                                onUpdate={updateCollection}
                                type="collection"
                            />}

                    </div>
                );
            })}
        </>);
    }

}

export default CollectionMain;