import React,{useState} from 'react';
import { Encryption } from '../../helpers';
import { selector } from '../../redux';
import { Follow } from '../../sdk';
import { toasterFunction } from '../DefaultComponents';
import { dispatch as parentDispatch } from "../../redux";
import { OwnUserUpdateByKey } from "../../redux/Actions";

const OtherProfileCta = (props) => {
    const [state,setState]=useState({
        loader:false,
        buttonDisable:false
    })
    const dispatch=parentDispatch();
    const ownUser=selector(state=>state.ownUser);
    const {friendShipStatus}=ownUser;
    const followToggle=async(userId,type="follwer",add=false)=>{
    
        try{
            setState((pre)=>{
                    return {...pre,loader:true,buttonDisable:true}
                })
                let result=await new Follow().followToggle({follow_to:Encryption.encryptBase64(userId)});
                dispatch(OwnUserUpdateByKey(type,result.data[type]))
                setState((pre)=>{
                    return {...pre,loader:false,buttonDisable:false}
                })
                toasterFunction.success(result.message);
            }catch(e){
                console.log(e);
                toasterFunction.error("something get wrong");
            }
            }
    if(friendShipStatus.followers.includes(props.userId)){
    return (<>
        <button className='btn site-btn btn-chat'>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20"><path d="M0,20v0L.01,2A2,2,0,0,1,2,0H18a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H4L0,20ZM4,10v2h8V10ZM4,7V9H16V7ZM4,4V6H16V4Z" fill="#fff" /></svg>
                Start Chat
            </span>
        </button>
        <button className='btn site-btn btn-follow' disabled={state.buttonDisable} onClick={()=>{return followToggle(props.userId,"followers")}}><span><i className='far fa-plus-circle'></i> <span>Follow Back{(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></span></button>
    </>);
}
else if(friendShipStatus.followBack.includes(props.userId)){
    return (<>
        <button className='btn site-btn btn-chat'>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20"><path d="M0,20v0L.01,2A2,2,0,0,1,2,0H18a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H4L0,20ZM4,10v2h8V10ZM4,7V9H16V7ZM4,4V6H16V4Z" fill="#fff" /></svg>
                Start Chat
            </span>
        </button>
        <button className='btn site-btn btn-follow' disabled={state.buttonDisable} onClick={()=>{return followToggle(props.userId,"followers")}}><span><i className='far fa-plus-circle'></i> <span>Unfollow {(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></span></button>
    </>);
}
else if(friendShipStatus.followings.includes(props.userId)){
    return (<>
        <button className='btn site-btn btn-chat'>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20"><path d="M0,20v0L.01,2A2,2,0,0,1,2,0H18a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H4L0,20ZM4,10v2h8V10ZM4,7V9H16V7ZM4,4V6H16V4Z" fill="#fff" /></svg>
                Start Chat
            </span>
        </button>
        <button className='btn site-btn btn-follow' disabled={state.buttonDisable} onClick={()=>{return followToggle(props.userId,"followings")}}><span><i className='far fa-plus-circle'></i> <span>Unfollow {(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></span></button>
    </>);
}
else{
    return (<>
        <button className='btn site-btn btn-chat'>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20"><path d="M0,20v0L.01,2A2,2,0,0,1,2,0H18a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H4L0,20ZM4,10v2h8V10ZM4,7V9H16V7ZM4,4V6H16V4Z" fill="#fff" /></svg>
                Start Chat
            </span>
        </button>
        <button className='btn site-btn btn-follow' disabled={state.buttonDisable} onClick={()=>{return followToggle(props.userId,"followings")}}><span><i className='far fa-plus-circle'></i> <span>Follow {(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></span></button>
    </>);
}

}

export default OtherProfileCta;