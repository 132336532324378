import React, { useState,useEffect } from 'react';
import CoverImgUploader from './CoverImgUploader';
import FollowOptCard from '../Categories/FollowOptCard';
import CollectionMain from './CollectionMain';
import UserPost from '../AllPosts/UserPost';
import OtherProfileCta from './OtherProfileCta';
import { useAuthContext } from '../../context';
import { useParams } from 'react-router-dom';
import Search from './Search';
import { Loader, toasterFunction } from '../DefaultComponents';
import { selector,dispatch as parentDisptach } from '../../redux';
import { userDataThunk } from '../../redux/Thunk';
import UserResource from '../AllPosts/UserResource';

const UserProfile = () => {
    const userAuth=useAuthContext();
    const [state, setState] = useState({
        toggleSteps:{
            profileStep1:true,
            profileStep2:false,
            profileStep3:false,
            profileStep4:false,
            profileStep5:false,
        },
        activeTab:1,
        loader:true,
        collections:[],
        resources:[],
        types:"",
        id:"",
        searchinput:"",
        posts:[],
        });
        let ownUser=selector(state=>state.ownUser);
        let friendStatus=ownUser.friendShipStatus;
        const dispatch=parentDisptach();
        const param=useParams();
        let id=false;
        if(param.id){
         id=param.id
        }
        let userDetails={
            loading:true,
            data:null,
            error:null,
        };
     
        if(id){
        userDetails=selector(state=>state.user);
        }
        else{
        userDetails={...ownUser,data:{...ownUser.data,...userAuth.user}}
        }
        
        let MyProfile=false;
      
        if(userDetails.data && userDetails.data.hasOwnProperty("id") && userDetails.data.id ===userAuth.user.id){
        MyProfile=true;
        }
       
        if(userDetails.error !==null){
        toasterFunction.error("something went Wrong")
         }
        useEffect(()=>{
        if(id){
            dispatch(userDataThunk(id));
        }
        },[dispatch,id])
        
       
        const tabToggle=async(currentIndex,prev=0,posts=[])=>{
        let toggleState={
           profileStep1:false,
           profileStep2:false,
           profileStep3:false,
           profileStep4:false,
           profileStep5:false,
        }
        let activeTab=(currentIndex+1)+prev;
        toggleState[`profileStep${activeTab}`]=true;
        let userPosts=userDetails.data.posts;
        if(posts.length){
            userPosts=posts
        }
        console.log(currentIndex,prev,posts)
        setState((pre)=>{
            return {...pre,toggleSteps:toggleState,activeTab:activeTab,id:"",types:"",
            collections:userDetails.data.collections,
            resources:userDetails.data.resources,
            posts:userPosts,
            followers:userDetails.data.followers,
            followings:userDetails.data.followings,
            user:userDetails.data
        
        };
        })
        
    }
    // console.log(state.resources)
   

    return (<>
        
       {userDetails.loading && <Loader/>}
       {(!userDetails.loading && !userDetails.error) &&  <main className='profile_view'>
            {/* Profile Cover Photo */}
           
               
                { <CoverImgUploader myProfile={MyProfile} userData={userDetails.data}/>}
            
           
            {/* Profile Img */}
            
             
            {/* Profile Description */}
            <div className="container">
                <div className="profile_description text-md-center">
                {userDetails.data.about && userDetails.data.about !==undefined && userDetails.data.about !=="undefined"?userDetails.data.about:""}
                </div>
            </div>
            {/* Profile Nav */}
            <div className="profile_nav">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        {/* Tabbing Section */}
                        <div className="profile_tab col-auto">
                            <nav>
                                <div className="nav nav-tabs border-0" id="nav-profileTab" role="tablist">
                                    <button className={`nav-link ${!state.toggleSteps.profileStep1 ? '' : 'active'}`} id="nav-follower-tab" onClick={()=>tabToggle(1,-1)}>
                                        {userDetails.data.followers.length}
                                        <span className='d-block'>Followers</span>
                                    </button>
                                    <button className={`nav-link ${!state.toggleSteps.profileStep2 ? '' : 'active'}`} id="nav-following-tab" onClick={()=>tabToggle(2,-1)}>
                                    {userDetails.data.followings.length}
                                        <span className='d-block'>Following</span>
                                    </button>
                                    {(MyProfile && <button className={`nav-link ${!state.toggleSteps.profileStep3 ? '' : 'active'}`} id="nav-collection-tab" onClick={()=>tabToggle(3,-1)}>
                                    {userDetails.data.collections.length}
                                        <span className='d-block'>Collection</span>
                                    </button>)}
                                    
                                  {userDetails.data.userType==="company" &&  <button className={`nav-link ${!state.toggleSteps.profileStep4 ? '' : 'active'}`} id="nav-resources-tab" onClick={()=>tabToggle(4,-1)}>
                                    {userDetails.data.resources.length}
                                        <span className='d-block'>Resources</span>
                                    </button>}
                                   
                                    <button className={`nav-link ${!state.toggleSteps.profileStep5 ? '' : 'active'}`} id="nav-publicPosts-tab" onClick={()=>tabToggle(5,-1)}>
                                    {userDetails.data.posts.length}
                                        <span className='d-block'>My Posts</span>
                                    </button>
                                </div>
                            </nav>
                        </div>
                        <div className="profile_cta col-auto">
                            {MyProfile ? <><button className='btn site-btn btn-chat' type='button' data-bs-toggle="modal" data-bs-target="#createCollection">
            <span>Create Collection</span>
        </button>
        {userDetails.data.userType==="company" &&   <button className='btn site-btn btn-follow' data-bs-toggle="modal" data-bs-target="#createResource">
            <span>Create Resource Libaray</span>
        </button>}
       </> : <OtherProfileCta userId={userDetails.data._id} parentState={{state:state,setState:setState}} />}
                        </div>
                    </div>
                </div>
            </div>
            {/* Profile Nav Tabs */}
            <div className="tab-content mt-5" id="nav-tabContent">
               {(state.toggleSteps.profileStep1) && <div className={`tab-pane container fade show active`} id="nav-follower" role="tabpanel" aria-labelledby="nav-follower-tab">
                    <div className="profile_tab_content">
                        {/* Profile Tab Head */}
                        <div className="profile_tab_head">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-auto">
                                    <h2>Followers</h2>
                                </div>
                                <Search type="followers"  parentState={{state:state,setState:setState}} />
                            
                            </div>
                        </div>
                        {/* Follower Cards */}
                        <div className="profile_followers row pt-3">
                        { userDetails.data.followers.filter(val=>{
                         const regex = new RegExp(state.searchinput, 'i');
                         const name = `${val.followByUser.firstName} ${val.followByUser.lastName}`;
                         
                         return regex.test(name);
                            
                        }).map((val) => {
                            if(val.followByUser){
                                return (
                                    <div className="col-lg-4 col-md-6" >
                                        <FollowOptCard
                                        key={val.id}
                                           parentState={{state:state,setState:setState}}
                                           userDetails={val.followByUser}
                                           type="followers"
                                           friendStatus={friendStatus}
                                           />
                                    </div>
                                );
                            }
                            return (<></>)
                                
                            })}
                        
                        </div>
                    </div>
                </div>} 
                {(state.toggleSteps.profileStep2 &&   <div className={`tab-pane container fade show active`} id="nav-following" role="tabpanel" aria-labelledby="nav-following-tab">
                    <div className="profile_tab_content">
                        {/* Profile Tab Head */}
                        <div className="profile_tab_head">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-auto">
                                    <h2>Followings</h2>
                                </div>
                                <Search type="followings"  parentState={{state:state,setState:setState}} />
                            </div>
                        </div>
                        {/* Follower Cards */}
                        <div className="profile_followers row pt-3">
                            {  userDetails.data.followings.filter(val=>{
                         const regex = new RegExp(state.searchinput, 'i');
                         const name = `${val.followByUser.firstName} ${val.followByUser.lastName}`;
                         console.log(name)
                         return regex.test(name);
                            
                        }).map((val) => {
                                if(val.followToUser){
                                    return (
                                        <div className="col-lg-4 col-md-6">
                                            <FollowOptCard
                                            key={val._id}
                                               parentState={{state:state,setState:setState}}
                                               userDetails={val.followToUser}
                                               type="followings"
                                               friendStatus={friendStatus}
                                               
                                               />
                                        </div>
                                    );
                                }
                                return (<></>)
                               
                            })}
                        </div>
                    </div>
                </div>)}
              {(state.toggleSteps.profileStep3)&&  <div className={`tab-pane container fade show active`} id="nav-collection" role="tabpanel" aria-labelledby="nav-collection-tab">
                    <div className="profile_tab_content">
                        {/* Profile Tab Head */}
                        <div className="profile_tab_head">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-auto">
                                    <h2>{state.type==="collections" ? state.collections[0].name : 'Collection'}</h2>
                                </div>
                                
                                <Search type="collections"  parentState={{state:state,setState:setState}} />
                                

                            </div>
                        </div>
                        {/* Collection Cards */}
                        <div className="collection_container row pt-3">
                             
                            {(state.type==="collections")?<UserPost type="collections" searchinput={state.searchinput}  parentState={{state:state,setState:setState}} data={userDetails.data.collections[0].posts}/>:<CollectionMain searchinput={state.searchinput} type="collections" parentState={setState} data={userDetails.data.collections} tabToggle={tabToggle}/>} 
                        </div>
                    </div>
                </div>}
                {(state.toggleSteps.profileStep4)&& 
                 <div className={`tab-pane container fade show active`} id="nav-collection" role="tabpanel" aria-labelledby="nav-collection-tab">
                 <div className="profile_tab_content">
                     {/* Profile Tab Head */}
                     <div className="profile_tab_head">
                         <div className="row justify-content-between align-items-center">
                             <div className="col-auto">
                                 <h2>{state.type==="resources" ? state.resources[0].name : 'Resources'}</h2>
                             </div>
                             
                             <Search type="followers"  parentState={{state:state,setState:setState}} />
                         </div>
                     </div>
                     {/* Collection Cards */}
                     <div className="collection_container row pt-3">
                    
                     {state.types !=="" && <>
                     
                      <div className="col-12 mb-3">
                      <button className='btn p-0 res_back_btn' onClick={()=>tabToggle(4,-1)}><i class="fal fa-arrow-left" ></i> Back</button>
                   </div>
                     
                     <CollectionMain backButton={true} data={state.resources} type="resources" description={true} onProfile={true} parentState={setState} tabToggle={tabToggle}/>
                     
                     </>
                     }
                        
                         {(state.types==="")?<CollectionMain data={state.resources} type="resources" parentState={setState} tabToggle={tabToggle}/>:  <UserResource type="resources" parentState={{state:state,setState:setState}} data={state.resources[0].items} parentId={state.resources[0]._id}/>} 
                     </div>
                 </div>
             </div> 
                }
                {(state.toggleSteps.profileStep5)&& 
                <div className={`tab-pane container fade show active`} id="nav-publicPosts" role="tabpanel" aria-labelledby="nav-publicPosts-tab">
                <div className="profile_tab_content">
                    {/* Profile Tab Head */}
                    <div className="profile_tab_head">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                                <h2>Public Posts</h2>
                            </div>
                            <Search type="posts"  parentState={{state:state,setState:setState}} />
                        </div>
                    </div>
                    {/* Follower Cards */}
                    <div className="profile_followers row pt-3">
                        
                            
                                
                                    <UserPost myProfile={MyProfile} type="profileDetails" parentState={{state:state,setState:setState}} check="post" data={state.posts}/>
                                
                           

                    </div>
                </div>
            </div>
                }
                
            </div>
        </main>}
       
        
    </>);
   }
    




export default UserProfile;