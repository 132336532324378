import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../context';
import Multiselect from 'multiselect-react-dropdown';
import { Modal } from '../../helpers';
import { selector } from '../../redux';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

const PostFields = (props) => {
    const [subCategoryError, setSubCategoryError] = useState(false);
    
    const ownUser = selector(state => state.ownUser);
    const getUserCategories = () => {
        let userIntersts = ownUser.data.intersts;
        let arr = [];
        let userCategories = [];
        if (userIntersts.length) {
            for (let value of userIntersts) {
                if (!arr.includes(value.category_id)) {
                    arr.push(value.category_id);
                }
            }
        }

        if (props.data.state.categories.length) {
            for (const value of props.data.state.categories) {
               
                    userCategories.push(value);
                
            }
        }
        return userCategories;
    }
    let userCategories = getUserCategories();
    let selectedCategory=[];
    let selectSubCategory=[];
    let selectedCollections=[];
    let selectedResources=[];
    if(props.data.state.formData.categories.length){
        let check=userCategories.find(val=>val._id ===props.data.state.formData.categories[0] );
        if(check){
            selectedCategory.push(check);
        }
        
        if(props.data.state.formData.tags.length){
            let check2=check.subCategories.find(val=>val._id===props.data.state.formData.tags[0])
            if(check2){
                selectSubCategory.push(check2);
            }
            
        }
    }
   if(props.data.state.formData.collections.length){
        selectedCollections=ownUser.data.collections.filter(val=>props.data.state.formData.collections.includes(val._id));
    }
    if(props.data.state.formData.resources.length){
        selectedResources=ownUser.data.resources.filter(val=>props.data.state.formData.resources.includes(val._id));
    }
    useEffect(() => {
        if (selectedCategory.length !== 0 && selectSubCategory.length === 0) {
            setSubCategoryError(true);
        } else {
            setSubCategoryError(false);
        }
    }, [selectedCategory, selectSubCategory]);

    return (<>
        {/* Text Input Fields */}
        <div className="post-field-title">
            <label htmlFor="title">Title</label>
            <input type="text" name="title" value={props.data.state.formData.title} onChange={props.data.onHandler} className='form-control' placeholder='Any Text Here' maxlength="100" />
            {props.errors.title.map((val, index) => {
                return (<>
                    <p className='form_error'>{val}</p>
                </>)
            })}

        </div>
        {/* Text Input Category */}
        <div className="post-field-category">
            <label htmlFor="postCategory">Category</label>
            <Multiselect
                displayValue="name"
                selectedValues={selectedCategory}
                customCloseIcon={<><i className='fal fa-times'></i></>}
                onKeyPressFn={function noRefCheck() { }}
                singleSelect={true}
                onRemove={function noRefCheck(value, selectItem) {
                    props.data.state.formData.categories = props.data.state.formData.categories.filter((val) => {
                        return val !== selectItem._id;
                    })
                    let getTags = ownUser.data.intersts.filter((val) => {
                        return props.data.state.formData.categories.includes(val.category_id);
                    })
                    props.data.parentStateFun((pre) => {
                        return { ...pre, ...props.data.state, tags: getTags }
                    });
                }}
                onSearch={function noRefCheck() { }}
                onSelect={function noRefCheck(value, selectItem) {
                    let arr=[];
                    arr.push(selectItem._id)
                    props.data.state.formData.categories=arr;
                    
                    let getTags = []
                    if(userCategories.length){
                        for(let val of userCategories){
                            if(props.data.state.formData.categories.includes(val._id)){
                              getTags=[...getTags,...val.subCategories]
                           }
                            
                           
                    }
                }
                   
                    props.data.parentStateFun((pre) => {
                        return { ...pre, ...props.data.state, tags: getTags }
                    });
                }}
                placeholder="Select Category"
                options={userCategories}
                showCheckbox
                avoidHighlightFirstOption={true}
            />
            {props.errors.categories.map((val, index) => {
                return (<>
                    <p className='form_error'>{val}</p>
                </>)
            })}
        </div>
        {/* Text Input description */}
        <div className="post-field-description">
            <label htmlFor="postDescription">Description</label>
            <textarea className='form-control' name="description" value={props.data.state.formData.description} onChange={props.data.onHandler} rows={9} placeholder={`Enter Description (Max ${props.data.state.descriptionLength})`} maxlength={props.data.state.descriptionLength}>{props.data.state.formData.description}</textarea>
            {props.errors.description.map((val, index) => {
                return (<>
                    <p className='form_error'>{val}</p>
                </>)
            })}
        </div>
        {/* Text Input Category */}
        <div className="post-field-tags">
            <label htmlFor="postTags">Sub categories</label>
            <Multiselect
                className={subCategoryError ? "empty-field" : ""}
                displayValue="name"
                customCloseIcon={<><i className='fal fa-times'></i></>}
                onKeyPressFn={function noRefCheck() { }}
                singleSelect={true}
                onRemove={function noRefCheck(value, selectItem) {
                    props.data.state.formData.tags = props.data.state.formData.tags.filter((val) => {
                        return val !== selectItem._id;
                    })

                    props.data.parentStateFun((pre) => {
                        return { ...pre, ...props.data.state }
                    });
                }}
                onSearch={function noRefCheck() { }}
                onSelect={function noRefCheck(value, selectItem) {
                    let arr=[];
                    arr.push(selectItem._id)
                    props.data.state.formData.tags=arr;
                    props.data.parentStateFun((pre) => {
                        return { ...pre, ...props.data.state }
                    });
                }}
                placeholder={"Select Subcategories"}
                options={props.data.state.tags}
                showCheckbox
                avoidHighlightFirstOption={true}
                selectedValues={selectSubCategory}
            />
            {props.errors.tags.map((val, index) => {
                return (<>
                    <p className='form_error'>{val}</p>
                </>)
            })}
        </div>
        {/* Text Input Category */}
        <div className="post-field-tags">
            <label htmlFor="postTags">Search Tags</label>
            <TagsInput value={props.data.state.formData.seoTags} onChange={(e)=> {props.data.setSeoTag(e)}} name="seoTags" addKeys={[188]}/>
            {/* <TagsInput value={props.data.state.formData.seoTags} onChange={props.data.setSeoTagss} name="seoTags" /> */}
            {/* <Multiselect
                displayValue="name"
                customCloseIcon={<><i className='fal fa-times'></i></>}
                onKeyPressFn={function noRefCheck() { }}
                onRemove={function noRefCheck(value, selectItem) {
                    props.data.state.formData.tags = props.data.state.formData.tags.filter((val) => {
                        return val !== selectItem._id;
                    })

                    props.data.parentStateFun((pre) => {
                        return { ...pre, ...props.data.state }
                    });
                }}
                onSearch={function noRefCheck() { }}
                onSelect={function noRefCheck(value, selectItem) {
                    props.data.state.formData.tags.push(selectItem._id);
                    props.data.parentStateFun((pre) => {
                        return { ...pre, ...props.data.state }
                    });
                }}
                placeholder={"Select Subcategories"}
                options={props.data.state.tags}
                showCheckbox
                avoidHighlightFirstOption={true}
            /> */}
            {props.errors.tags.map((val, index) => {
                return (<>
                    <p className='form_error'>{val}</p>
                </>)
            })}
        </div>
        {/* Toggle Buttons */}
        <div className="post-field-toggleBtn d-flex">
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" name="collection" defaultChecked={props.data.state.formData.collection} onChange={props.data.onHandler} />
                <label className="form-check-label" htmlFor="addCollectionToggle">Add to Collection</label>
            </div>
            {/* {userAuth.user.userType==="company" &&  <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" name="resource" defaultChecked={props.data.state.formData.resource} onChange={props.data.onHandler}/>
                <label className="form-check-label" htmlFor="resource">Add to Resource</label>
            </div>} */}

            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" role="switch" name="visibiltyMode" defaultChecked={(props.data.state.formData.visibiltyMode === "public") ? true : false} onChange={props.data.onHandler} />
                <label className="form-check-label" htmlFor="publicToggle">Public</label>
            </div>

        </div>
        {/* Collection */}
        {props.data.state.formData.collection ?
            <div className="post-field-collect row align-items-end">
                <div className="col-md-9">
                    <label htmlhtmlFor="postCollections">Collections</label>
                    <Multiselect
                        displayValue="name"
                        customCloseIcon={<><i className='fal fa-times'></i></>}
                        onKeyPressFn={function noRefCheck() { }}
                        onRemove={function noRefCheck(value, selectItem) {
                            props.data.state.formData.collections = props.data.state.formData.collections.filter((val) => {
                                return val !== selectItem._id;
                            })
                            props.data.parentStateFun((pre) => {
                                return { ...pre, ...props.data.state }
                            });
                        }}
                        onSearch={function noRefCheck() { }}
                        onSelect={function noRefCheck(value, selectItem) {
                            props.data.state.formData.collections.push(selectItem._id);
                            props.data.parentStateFun((pre) => {
                                return { ...pre, ...props.data.state }
                            });
                        }}
                        placeholder={"Select Collections"}
                        options={ownUser.data.collections}
                        showCheckbox
                        avoidHighlightFirstOption={true}
                        selectedValues={selectedCollections}
                    />
                    {props.errors.collections.map((val, index) => {
                        return (<>
                            <p className='form_error'>{val}</p>
                        </>)
                    })}
                </div>
                <div className="col-md-3">
                    <button className='btn site-btn' type='button' id="#createCollection" onClick={(e, id = "#createCollection") => {
                        Modal.hideTab("#addPostPopup");
                        localStorage.setItem("modal","#addPostPopup");
                        Modal.showTab(id);
                    }}><span>Create New Collection</span></button>
                </div>
            </div> : ''}

        {props.data.state.formData.resource ?
            <div className="post-field-collect row align-items-end">
                <div className="col-md-9">
                    <label htmlhtmlFor="postCollections">Resource</label>
                    <Multiselect
                        displayValue="name"
                        customCloseIcon={<><i className='fal fa-times'></i></>}
                        onKeyPressFn={function noRefCheck() { }}
                        onRemove={function noRefCheck(value, selectItem) {
                            props.data.state.formData.resources = props.data.state.formData.resources.filter((val) => {
                                return val !== selectItem._id;
                            })
                            props.data.parentStateFun((pre) => {
                                return { ...pre, ...props.data.state }
                            });
                        }}
                        onSearch={function noRefCheck() { }}
                        onSelect={function noRefCheck(value, selectItem) {
                            props.data.state.formData.resources.push(selectItem._id);
                            props.data.parentStateFun((pre) => {
                                return { ...pre, ...props.data.state }
                            });
                        }}
                        placeholder={"Select Resources"}
                        options={ownUser.data.resources}
                        showCheckbox
                        avoidHighlightFirstOption={true}
                        selectedValues={selectedResources}
                    />
                    {props.errors.resources.map((val, index) => {
                        return (<>
                            <p className='form_error'>{val}</p>
                        </>)
                    })}
                </div>
                <div className="col-md-3">
                    <button className='btn site-btn' type='button' id="#createResource" onClick={(e, id = "#createResource") => {

                        Modal.hideTab("#addPostPopup");
                        Modal.showTab(id);
                    }}><span>Create New Resources</span></button>
                </div>
            </div> : ''}



    </>);
}

export default PostFields;