import { Get_Categories } from "../ActionConstants"
let intialState={
    loading:true,
    data:[],
    error:null,
   
}
export const getCategoriesReducer=(state=intialState,{type,payload})=>{
switch (type) {
     case Get_Categories.success:
    return {...state,data:payload,loading:false}
    case Get_Categories.error:
        
    return {...state,error:payload,loading:false,data:[]}
    default:
    return state
        
}
}