import React from 'react';
const ProfileImgUploader = (props) => {
    
    return (<>
        <div className="profile_img_uploader">
            <div className="uploader d-inline-block position-relative">
                <img src={props.value.showProflePic} alt="Profile Img" className='profile' />
                 <button className='btn p-0 shadow-none'>
                    <input className='uploader_input' type="file" name="profile_pic" id="ProfileImgUploader" accept='image/*' onChange={props.setValue} />
                    <img src="assets/images/uploader_icon.svg" alt="Img Uploadern Icon" />
                </button> 
               
            </div>
            {/* {props.error.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                           })}
            <p>{userAuth.user.firstName} {userAuth.user.lastName}</p> */}
        </div>
    </>);
}

export default ProfileImgUploader;