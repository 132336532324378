import React from 'react';
import { Routes,Route} from "react-router-dom";
import Home from './../Components/AllPosts/Home';
import PostDetail from './../Components/AllPosts/PostDetail';
import CategoryPage from './../Components/Categories/CategoryPage';
import Landing from './../Components/Landing';
import VerifyEmail from './../Components/ChangeEmail';
import PrivacyPolicy from './../Components/PrivacyPolicy';
import CompleteProfile from './../Components/Profile/CompleteProfile';
import Profile from './../Components/Profile/Profile';
import ProfileSettings from './../Components/Profile/ProfileSettings';
import TermsCondition from './../Components/TermsCondition';
import { urlRoutes } from './../config';
import  {PrivateRoutes}  from './PrivateRoutes';
import  {PublicRoutes}  from './PublicRoutes';
import ComingSoon from '../Components/ComingSoon';
import CollectionResourceDetail from '../Components/CollectionResourceDetail';
import ContactUs from '../Components/ContactUs';
import { Login, Register } from '../Components/popup';
import SearchPost from '../Components/Categories/searchPost';
import { RestrictedRoutes } from './RestrictedRoutes';
import UserProfile from '../Components/Profile/UserProfile';




const Routers = () => {
    return (<>
        <Routes>
           
            <Route element={<PublicRoutes/>}>
            <Route path={urlRoutes.unAuthorize.landing} element={<Landing />} />
            <Route path={urlRoutes.unAuthorize.termsAndCondition} element={<TermsCondition />} />
            <Route path={urlRoutes.unAuthorize.categories} element={<CategoryPage />} />
            <Route path={urlRoutes.unAuthorize.privacyPolicy} element={<PrivacyPolicy />} />
            <Route path={urlRoutes.unAuthorize.resetPassword} element={<Landing />} />
           
            <Route exact path='/coming-soon' element={<ComingSoon />} />
            <Route exact path='/contact' element={<ContactUs />} />
            <Route path={'/search'} element={<SearchPost />} />
            </Route>
            <Route element={<RestrictedRoutes/>}>
            <Route exact path='/join-community' element={<Register />} />
            <Route exact path='/login' element={<Login />} />
            </Route>
           
            <Route element={<PrivateRoutes/>}>
            <Route path={urlRoutes.authorize.completeProfile}  element={<CompleteProfile />} />
            <Route path={urlRoutes.authorize.postDetails} exact element={<PostDetail  />}></Route>
            <Route path={urlRoutes.authorize.userProfile} element={<UserProfile />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path={urlRoutes.unAuthorize.home} element={<Home  />} />
            <Route path={urlRoutes.unAuthorize.company} element={<Home  CheckCompanyPage={true} />} />
            <Route path={urlRoutes.authorize.settings} element={<ProfileSettings />} />
            <Route path="/settings/:query" element={<ProfileSettings/>} />
            <Route path={urlRoutes.authorize.changeEmail} element={<VerifyEmail  />} />
            <Route path='/resource-detail/:id/:parentId' element={<CollectionResourceDetail/>} />
            <Route path={urlRoutes.unAuthorize.generatePassword} element={<ProfileSettings />} />
            </Route>
           </Routes>
    </>);
}

export default Routers;
