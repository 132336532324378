export const Get_User={
    success:"get_user",
    update:"get_user_update",
    error:"get_user_error"
}
export const Get_Categories={
    success:"get_categories",
    error:"get_categories_error"
}
export const User_Post={
    success:"get_user",
    error:"get_user_error"
}
export const Categories_Post={
    success:"get_categories_post",
    error:"get_categories_post_error"
}
export const Update_User={
    error:"update_user_error",
    success:"update_user_success",
}
export const Own_User={
    success:"own_user_success",
    update:"own_user_update",
    error:"own_user_error",
}
export const Friend_Status={
    success:"own_user_success",
    update:"own_user_update",
    error:"own_user_error",
}
export const My_Post_Action={
    success:"my_post_action_success",
    update:"my_post_action_update",
    error:"my_post_action_error",
}
export const Other_Post_Action={
    success:"other_post_action_success",
    update:"other_post_action_update",
    error:"other_post_action_error",
}