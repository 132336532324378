import React from 'react';
import FollowOptCard from './FollowOptCard';
import { useAuthContext } from "../../context";
import { useNavigate } from 'react-router-dom';
import { Modal } from '../../helpers';
import { selector } from '../../redux';
const Categorycard = (props) => {
   
    const userAuth=useAuthContext();
    const navigate=useNavigate();
    const ownUser=selector(state=>state.ownUser);
   let friendStatus=ownUser.friendShipStatus;
    const onClickHandler=(postId)=>{
        if(!userAuth.isAuthenticated){
            Modal.showTab("#loginForm");
                return false;
            }
            else{
                navigate(`/postdetails/${postId}`)
            }
        
    }
    
    return (<>
        {/* Item Class Declare Slider Item */}
        {(props.postDetails.length)?props.postDetails.map((val) => {
            
                if(val.media.length && val.user){
                    return(<>
                        <div key={val._id} className={props.class}>
                     <div className={`category-card ${val.user.userType==="company" ?'with-label':''}`}>
                         <div className="thumb">
                             <div className="enterprise-label position-absolute">
                                 <img src="assets/images/enterprise-vector.svg" alt="Enterprise Icon" /> {(val.user.userType==="company")?"From Company":""}
                             </div>
                             {val.postType ==="blog" && <div className="card_feature"><h3>{val.title}</h3><p>{val.description}</p> <a onClick={()=>onClickHandler(val._id)}>ReadMore</a></div> }
                             {(val.postType ==="image" || val.postType ==="video") &&<a onClick={()=>onClickHandler(val._id)} style={{width: "100%",cursor:"pointer"}}> <img src={val.media[0].media_url} alt={val.media[0].media_url} className='main_img' /> </a>}
                             {/* {val.postType==="video" && <button onClick={()=>onClickHandler(val._id)} className='btn border-0 post-video'  data-fancybox="" style={{width: "100%"}}>
                             <img src={val.media[0].media_url} alt={val.media[0].media_url} className='main_img' />
                     <img src="assets/images/play-icon.svg" alt="Play Icon" className="play-icon" />
                 </button>} */}
                           
                         </div>
                         <FollowOptCard 
                             userDetails={val.user}
                             friendStatus={friendStatus}
                             dashBoard={true}
                             postId={val._id}
                             postDetails={val}
                             onClickTarget={props.onClickTarget}
                             type="completeProfile"
                             ownUser={userAuth.isAuthenticated && val.user_id===userAuth.user.id}
                             
                         />
                         
                     </div>
                 </div>
                       </>)
                }
                else{
                    if(val.postType==="blog"){
                        return(<>
                            <div key={val._id} className={props.class}>
                         <div className={`category-card ${val.user.userType==="company" ?'with-label':''}`}>
                             <div className="thumb">
                                 <div className="enterprise-label position-absolute">
                                     <img src="assets/images/enterprise-vector.svg" alt="Enterprise Icon" /> {(val.user.userType==="company")?"From Company":""}
                                 </div>
                                 {val.postType ==="blog" && <div className="card_feature"><h3>{val.title}</h3><p>{val.description}</p> <a onClick={()=>onClickHandler(val._id)}>ReadMore</a></div> }
                                 {(val.postType ==="image" || val.postType ==="video") &&<a onClick={()=>onClickHandler(val._id)} style={{width: "100%",cursor:"pointer"}}> <img src={val.media[0].media_url} alt={val.media[0].media_url} className='main_img' /> </a>}
                                 {/* {val.postType==="video" && <button onClick={()=>onClickHandler(val._id)} className='btn border-0 post-video'  data-fancybox="" style={{width: "100%"}}>
                                 <img src={val.media[0].media_url} alt={val.media[0].media_url} className='main_img' />
                         <img src="assets/images/play-icon.svg" alt="Play Icon" className="play-icon" />
                     </button>} */}
                               
                             </div>
                             <FollowOptCard 
                                 userDetails={val.user}
                                 friendStatus={friendStatus}
                                 dashBoard={true}
                                 postId={val._id}
                                 postDetails={val}
                                 onClickTarget={props.onClickTarget}
                                 type="completeProfile"
                                 ownUser={userAuth.isAuthenticated && val.user_id===userAuth.user.id}
                                 
                             />
                             
                         </div>
                     </div>
                           </>)
                    }
                }
             
            }):(props.error)?props.error:<p className='text-center'>No current recommendations, please check later</p>}
       
    </>);
}

export default Categorycard;