import React from 'react';

const CompanySearch = () => {
    return (<>
        <div className="copany_search">
            <form>
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6">
                        <select name="companyLocation" id="companyLocation" className='form-select'>
                            <option defaultValue>Location</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                    <div className="col-lg-5 col-md-6">
                        <div className="input-group search_field_icon">
                            <input type="search" className="form-control border-end-0" placeholder="Search Company" aria-label="Search Company" aria-describedby="button-search" />
                            <button className="btn shadow-none border-start-0" type="button" id="button-search"><i className="far fa-search"></i></button>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <button type="submit" className='btn site-btn'><span>Search</span></button>
                    </div>
                </div>
            </form>
        </div>
    </>);
}

export default CompanySearch;