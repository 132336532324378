import {  Storage } from "../helpers";
import {axios} from "./config"
export class Collection extends axios{
   axios;
   token;
   prefix;
   constructor(){
   super();
   this.axios=this.axiosConfig();
   this.token=Storage.getCookie(`${process.env.NODE_ENV}-userToken`);
   this.prefix="/collection/"
    }
    async createCollection(data){
    try{
    let formData=new FormData();
    if(Object.keys(data).length > 0){
    for(let key in data){
        if(key==="img_url"){
         if(data[key] && data[key] !==""){
            formData.append(key,data[key]);
         }
        }
        else{
            formData.append(key,data[key]);
        }
       
    }
    let result=await this.axios({
        method:"post",
        url:this.prefix.concat(""),
        data:formData,
        headers:{
        Authorization:this.token
        }
        });
        return new Promise((res,rej)=>{
        res(result.data);
        })
    }
   
    }catch(e){
    return new Promise((res,rej)=>{
    rej(e);
    })
    }
    }
    async updateCollection(data){
        try{
        let formData=new FormData();
        if(Object.keys(data).length > 0){
        for(let key in data){
            if(key==="img_url"){
             if(data[key] && data[key] !==""){
                formData.append(key,data[key]);
             }
            }
            else{
                formData.append(key,data[key]);
            }
           
        }
        let result=await this.axios({
            method:"post",
            url:this.prefix.concat("update"),
            data:formData,
            headers:{
            Authorization:this.token
            }
            });
            return new Promise((res,rej)=>{
            res(result.data);
            })
        }
       
        }catch(e){
        return new Promise((res,rej)=>{
        rej(e);
        })
        }
        }
    async deleteCollection(id){
            try{
            let result=await this.axios({
                method:"delete",
                url:this.prefix.concat(``),
                data:{
                    id
                },
                headers:{
                Authorization:this.token
                }
                });
                return new Promise((res,rej)=>{
                res(result.data);
                })
            
           
            }catch(e){
            return new Promise((res,rej)=>{
            rej(e);
            })
            }
        }
            
    async getCollection(id){
        try{
        let result=await this.axios({
            method:"get",
            url:this.prefix.concat(`getCollection/${id}`),
            headers:{
            Authorization:this.token
            }
            });
            return new Promise((res,rej)=>{
            res(result.data);
            })
        
       
        }catch(e){
        return new Promise((res,rej)=>{
        rej(e);
        })
        }
    }
    async addToCollection(data){
            try{
            let result=await this.axios({
                method:"get",
                url:this.prefix.concat(`addToCollection`),
                data:data,
                headers:{
                Authorization:this.token
                }
                });
                return new Promise((res,rej)=>{
                res(result.data);
                })
            
           
            }catch(e){
            return new Promise((res,rej)=>{
            rej(e);
            })
            }
     }
     async getCollectionByPattern(name="",userID){
        try{
        let result=await this.axios({
            method:"get",
            url:this.prefix.concat(`getByPattern/${name}/${userID}`),
            headers:{
            Authorization:this.token
            }
            });
            return new Promise((res,rej)=>{
            res(result.data);
            })
        
       
        }catch(e){
        return new Promise((res,rej)=>{
        rej(e);
        })
        }
    }

            }



