import { Storage } from "../helpers";
import { axios } from "./config"
export class Auth extends axios {
    axios;
    token;
    constructor() {
        super();
        this.axios = this.axiosConfig();
        this.token = Storage.getCookie(`${process.env.NODE_ENV}-userToken`);
    }
    async register(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: "/auth/register",
                data: data
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })

        }
    }
    async login(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: "/auth/login",
                data: data
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })

        }
    }
    async logout() {
        try {
            let result = await this.axios({
                method: "post",
                url: "/auth/logout",
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async socialLogin(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: "/auth/sociallogin",
                data: data
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async socialRegister(data) {
        try {
            let result = await this.axios({
                method: "post",
                url: "/auth/socialregister",
                data: data
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async verifyEmail(params) {
        try {
            let result = await this.axios({
                method: "get",
                url: "/auth/verifyemail",
                headers: {
                    Authorization: this.token
                },
                params: params
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async resendEmail() {
        try {
            let result = await this.axios({
                method: "post",
                url: "/auth/resendEmail",
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async forgotPassword(email) {
        try {
            let result = await this.axios({
                method: "post",
                url: "/auth/forgotPassword",
                data: { email: email }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })

        }
    }
    async forgotPasswordVerify(params) {
        try {
            let result = await this.axios({
                method: "get",
                url: "/auth/forgotPassword",

                params: params
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })

        }
    }
    async newPassword(data) {
        try {
            let result = await this.axios({
                method: "put",
                url: "/auth/forgotPassword",
                data: data
            });
            return new Promise((res, rej) => {
                res(result.data);
            })
        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })

        }
    }
}