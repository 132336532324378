import React,{useState,useEffect} from 'react';
import OwlCarousel from 'react-owl-carousel';
import Categorycard from './CategoryCard';
import { useAuthContext } from '../../context';
import { CollectionDashBoard } from '../popup/collectionDashBoard';
import { Modal } from '../../helpers';
import { selector,dispatch as parentDispatch } from '../../redux';
import { getCategoriesPostThunk } from '../../redux/Thunk';
import { CategoryPostSkeleton } from '../Skeleton';
const CategoryContainer = (props) => {
    const userAuth=useAuthContext();
    const [state,setState]=useState({postId:""});
    const posts=selector((state)=>state.categoryPost);
    let dispatch=parentDispatch();
    useEffect(()=>{
        if(window.localStorage.getItem("redirect")){
          window.localStorage.removeItem("redirect");
        }
        else{
            dispatch(getCategoriesPostThunk());
        }
        
    },[dispatch])
    const onClickTarget=(e,postId,target)=>{
        Modal.hideTab(".modal");
        setState((pre)=>{
            return {...pre,postId:postId}
        })
        
        Modal.showTab(target);
    }
    const backToHome=()=>{
        dispatch(getCategoriesPostThunk())
    }
    const onHandler=(categoryId=false,subCatid=false,limit=4)=>{
        dispatch(getCategoriesPostThunk(categoryId,subCatid,limit))
       }
    return (<>
    {posts.loading && 
    
<CategoryPostSkeleton count={4}/>
    }
    {(!posts.loading && posts.data.userCatSelectArr.length)?<><div class="mb-3" style={{display:"flex",justifyContent:"center"}}> <button type="button" class="btn site-btn" onClick={backToHome}><span>Back To Home</span></button></div> </>:"" }
    {!posts.loading && 
    
    
    posts.data.filterPost.map((val)=>{
        
return (<>
<div key={val._id} className="category-container">
            {/* Category Title */}
            <div className="category-title d-flex flex-wrap justify-content-between align-items-center">
                <h2>{val.name}</h2>
              {posts.data.limit <=4 && <a onClick={()=>(posts.data.filterItems[0].subCategories)?onHandler(val._id,false,4):onHandler(val.category_id,val._id,100)} style={{cursor:"pointer"}}><span>View More</span> <i className="fal fa-arrow-right"></i></a>}  
            </div>
            {/* Cards Container */}
            <div className="category-cards-container">
                {/* Carousel For Category Cards */}
                <OwlCarousel className='category-cards-slider owl-theme'
            loop={false}
            margin={15}
            nav={false}
            dots={false}
            responsive={{
                0: {
                    items: 1,
                    stagePadding: 20
                },
                768: {
                    items: 2,
                    stagePadding: 30
                },
                992: {
                    items: 3,
                    stagePadding: 30
                },
                1200: {
                    margin: 29,
                    items: 4,
                    stagePadding: 0,
                    touchDrag: false,
                    mouseDrag: false
                }
            }}>
            {/* Slider Items */}
            
                    <Categorycard
                    postDetails={val.posts}
                    error="There is no post against this"
                    onClickTarget={onClickTarget}
                    />
                
            
        </OwlCarousel>
            </div>
        </div>
</>)
    })
    
    }
    {(userAuth.isAuthenticated) &&  <CollectionDashBoard  postId={state.postId}/>}
       
    </>);
}

export default CategoryContainer;
