import React,{useState,} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LikeBtnContainer from './LikeBtnContainer';
import LikerImg from './LikerImg';
import PostDetailControls from './PostDetailControls';
import PostedImg from './PostedImg';
import PostedVideo from './PostedVideo';
import * as moment from "moment"
import { Collection } from '../popup/Collection';
import { Resources } from '../popup/Resources';
import { Encryption, Modal } from '../../helpers';
import EditPost from '../Addposts/EditPost';
import { Post } from '../../sdk';
import { toasterFunction } from '../DefaultComponents';
import { OwnUserUpdateByKey } from '../../redux/Actions';
import { dispatch as parentDispatch } from '../../redux';
import { useAuthContext } from '../../context';


const UserPost = (props) => {
const dispatch=parentDispatch();
const userAuth=useAuthContext();
const navigate=useNavigate();
const [state,setState]=useState({
    postId:"",
    details:{}
    
});
const redirect=(id)=>{
    navigate("/profile/"+id)
}
const editPost=(postId)=>{
    let post=props.data.find(val=>val._id===postId);
    if(post){
        Modal.hideTab(".modal");
        setState((pre)=>{
            return {...pre,details:post}
        });
        Modal.showTab("#editPostpopup")
    }
    else{
        alert("data not found")
    }
}
const deletePost=async(id)=>{
    try{
        if(window.confirm("’Are you sure you would like to delete this post?")){
            await new Post().deletePost(id);
            dispatch(OwnUserUpdateByKey("posts",{_id:id},"remove"));
            toasterFunction.success("post has been deleted");
        }
      
    }
    catch(e){
toasterFunction.error("something went wrong")
}
}
const onClickTarget=(e,postId,target)=>{
    Modal.hideTab(".modal");
    state.postId=postId
    
    setState((pre)=>{
        return {...pre,postId:postId}
    })
    Modal.showTab(target);
    
}


return (

<>
{props.data.filter(val=>{
                         const regex = new RegExp(props.searchinput, 'i');
                         const name = `${val.title}`;
                         return regex.test(name);
                            
                        }).map((val,index)=>{
   if(val.media.length && val._id){
    return (<>
    <div className={`public_posts ${props.type !=="details" && props.type !=="home"? "col-md-6":""}`} >
        <div className={`post_container`}>
            
            {props.type ==="details" ?'' :
                <div className="follow-card-opt">
                    <div className="card-admin d-flex flex-wrap justify-content-between align-items-center">
                        <div className="admin-info d-flex align-items-center" onClick={()=>redirect(Encryption.encryptBase64(val._id))}>
                           {val?.user?.profile_pic && <img src={(val.user.profile_pic)?val.user.profile_pic:"assets/images/avatar.jpg"} alt="Admin Profile Img" />} 
                            <div className="admin-name">
                                <p>{val.user.firstName} {val.user.lastName}</p>
                                <span>{val.user.username}</span>
                            </div>
                        </div>
                        <p className='post_time'>{moment(val.createdAt).fromNow()}</p>
                    </div>
                </div>
            }
            
            <div className={`post-visual ${val.postType==="blog"?"blog-container":""}`} onClick={()=>{
                if(val.postType !=="details"){
                    navigate(`/postdetails/${val._id}`);
                }
            }}>
            {val.postType==="blog" && 
           <div className="blog-details"><h3>{val.title}</h3><p>{val.description}</p> </div>
           } 
          
                {val.postType==="image" ? <PostedImg postImgSrc={val.media[0].media_url} /> : (val.postType==="video" && <PostedVideo postVideoSrc={val.media[1].media_url} postVideoImg={val.media[0].media_url} />) }
            </div>
           
            <div className={`post-bottom pt-0`}>
                
            
                
               
                <div className={`post-controls d-flex flex-wrap align-items-center ${props.PostDetailPage ? 'mt-0' : ''}`}>
                    
                    <div className="liker_icon d-flex align-items-center">
                        {val.likes.map((val2) => {
                            return (
                                <LikerImg
                                    key={val2.id}
                                    likeUserImg={(val2.profile_pic)?val2.profile_pic:"assets/images/avatar.jpg"}
                                    
                                />
                            );
                        })}
                        <p className='count_likes'>+{val.likes.length}</p>
                    </div>
                    
                    <LikeBtnContainer myProfile={props.myProfile}  type={props.type} parentState={props.parentState} data={val.likes} post_id={val._id}/>
                     
                    <div className="post-action-comment">
                    {props.type!=="details"?<Link to={`/postdetails/${val._id}`} className='btn comment-btn p-0 lh-1'><img src="assets/images/chat-icon.svg" alt="" /> Comment</Link>:<a onClick={()=>props.onComment()} className='btn comment-btn p-0 lh-1'><img src="assets/images/chat-icon.svg" alt="" /> Comment</a>}
                        
                    </div>
                  <div className="post-action-collection">
                        <button className="btn p-0 lh-1 collection-btn" onClick={(e)=>onClickTarget(e,val._id,"#selectCollection")}>
                            <i className="far fa-plus-circle"></i>
                            <span className='d-none d-md-inline-block'>Add to</span> Collection
                        </button>
                    </div>
                    
                    {(props.type!=="details" && props.myProfile && val.user_id === userAuth.user.id) && <><button className="btn p-0"><img src="assets/images/edit.svg" alt="Edit Icon" onClick={()=>editPost(val._id)} /></button><button className="btn p-0" onClick={()=>deletePost(val._id)}><img src="assets/images/delete.svg" alt="Edit Icon" /></button></>}
                  {props.type ==="details" && <PostDetailControls postTime={props.postTime} />}
                </div>
            </div>
        </div>
        </div>
    </>);
   }
   else{
       if(val.postType==="blog"){
        return (<>
            <div className={`public_posts ${props.type !=="details" && props.type !=="home"? "col-md-6":""}`} >
                <div className={`post_container`}>
                    
                    {props.type ==="details" ?'' :
                        <div className="follow-card-opt">
                            <div className="card-admin d-flex flex-wrap justify-content-between align-items-center">
                                <div className="admin-info d-flex align-items-center">
                                    <img src={(val.user.profile_pic)?val.user.profile_pic:"assets/images/avatar.jpg"} alt="Admin Profile Img" />
                                    <div className="admin-name">
                                        <p>{val.user.firstName} {val.user.lastName}</p>
                                        <span>{val.user.username}</span>
                                    </div>
                                </div>
                                <p className='post_time'>{moment(val.createdAt).fromNow()}</p>
                            </div>
                        </div>
                    }
                    
                    <div className={`post-visual ${val.postType==="blog"?"blog-container":""}`} onClick={()=>{
                if(val.postType !=="details"){
                    navigate(`/postdetails/${val._id}`);
                }
            }}>
                    {val.postType==="blog" && 
                   <div className="blog-details"><h3>{val.title}</h3><p>{val.description}</p> </div>
                   } 
                  {val.postType==="image" ? <PostedImg postImgSrc={val.media[0].media_url} /> : (val.postType==="video" && <PostedVideo postVideoSrc={val.media[1].media_url} postVideoImg={val.media[0].media_url} />) }
                    </div>
                    <div className={`post-bottom pt-0`}>
                    <div className={`post-controls d-flex flex-wrap align-items-center ${props.PostDetailPage ? 'mt-0' : ''}`}>
                            
                            <div className="liker_icon d-flex align-items-center">
                                {val.likes.map((val2) => {
                                    return (
                                        <LikerImg
                                            key={val2.id}
                                            likeUserImg={(val2.profile_pic)?val2.profile_pic:"assets/images/avatar.jpg"}
                                            
                                        />
                                    );
                                })}
                                <p className='count_likes'>+{val.likes.length}</p>
                            </div>
                            
                            <LikeBtnContainer myProfile={props.myProfile} type={props.type} parentState={props.parentState} data={val.likes} post_id={val._id}/>
                             
                            <div className="post-action-comment">
                            {props.type!=="details"?<Link to={`/postdetails/${val._id}`} className='btn comment-btn p-0 lh-1'><img src="assets/images/chat-icon.svg" alt="" /> Comment</Link>:<a onClick={()=>props.onComment()} className='btn comment-btn p-0 lh-1'><img src="assets/images/chat-icon.svg" alt="" /> Comment</a>}
                                
                            </div>
                          <div className="post-action-collection">
                                <button className="btn p-0 lh-1 collection-btn" onClick={(e)=>onClickTarget(e,val._id,"#selectCollection")}>
                                    <i className="far fa-plus-circle"></i>
                                    <span className='d-none d-md-inline-block'>Add to</span> Collection
                                </button>
                            </div>

                            {(props.type!=="details" && props.myProfile && val.user_id === userAuth.user.id) && <><div className="post-action-collection"><button className="btn"><img src="assets/images/edit.svg" alt="Edit Icon" onClick={()=>editPost(val._id)} /></button>
                           
                            <button className="btn" onClick={()=>deletePost(val._id)}><img src="assets/images/delete.svg" alt="Edit Icon" /></button>
                            </div>
                            </>}
                          {props.type ==="details" && <PostDetailControls postTime={props.postTime} />}
                        </div>
                    </div>
                </div>
                </div>
            </>);
       }
   }
   })}

   <Collection postId={state.postId}/>
   <Resources postId={state.postId}/>
   <EditPost details={state.details}/>
   
</>)
}

export default UserPost;