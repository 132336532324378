import React from 'react';

const PostedVideo = (props) => {
    return (<>
        <button data-src={props.postVideoSrc} className='btn border-0 post-video' data-fancybox="">
            <img className="videoimg" src={props.postVideoImg} alt="Video Thumbnail"  loading="lazy" />
            <img src="assets/images/play-icon.svg" alt="Play Icon" className="play-icon" />
        </button>
    </>);
}

export default PostedVideo;