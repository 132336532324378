export class passwordStrength{
    password;
    constructor(password){
    this.password=password;
    }
    checkPassword(){
       const veryWeakRegex = new RegExp(
            /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(?=.{3,5}).+/g
          );
          const weakRegex = new RegExp(
            /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(?=.{6,7}).+/g
          );
          const strongRegex = new RegExp(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,15})'
          );
          const veryStrongRegex = new RegExp(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{16,64})'
          );
          let obj={
            veryWeak:{background:""},
            weak:{background:""},
            strong:{background:""},
            veryStrong:{background:""},
            
          }
          if(veryWeakRegex.test(this.password)){
            console.log("here")
            obj={
                veryWeak:{background:"#E22357"},
                weak:{background:""},
                strong:{background:""},
                veryStrong:{background:""}
            }
          }
          if(weakRegex.test(this.password)){
            obj={
                weak:{background:"#F98D00"},
                veryWeak:{background:"#E22357"},
                strong:{background:""},
                veryStrong:{background:""}
            }
          }
          if(strongRegex.test(this.password)){
              obj={
                strong:{background:"#93ccaf"},
                veryWeak:{background:"#E22357"},
                weak:{background:"#F98D00"},
                veryStrong:{background:""}
              }
          }
           if(veryStrongRegex.test(this.password)){
            obj={
                veryStrong:{background:"#40B680"},
                strong:{background:"#93ccaf"},
                veryWeak:{background:"#E22357"},
                weak:{background:"#F98D00"},
            }
        }
        return obj;
    }
}