import React from 'react';

const ImgUrlField = (props) => {
    return (<>
    <div style={{display:"flex",justifyContent:"space-between"}} >
        <input type="url" className='form-control' name={props.name} value={props.value} id="imgUrlUploader" onChange={props.fun} placeholder='http:\\' disabled={props.disabled} style={{width:"85%"}}/>
        <button type='button' className='btn site-btn' style={{width:"14%",height:"57px"}} disabled={props.buttonDisable} onClick={()=>props.searchFromUrl(props.name)}>Search</button>
        </div>
        {props.errors.mediaGettingUrl.map((val,index)=>{
                                             return (<>
                                             <p className='form_error'>{val}</p> 
                                             </>)
                                           })}
        
        
    </>);
}

export default ImgUrlField;