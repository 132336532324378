import React, { useState } from 'react';
import { dataValidation, passwordStrength, Validate, Modal } from './../../helpers';
import Recaptcha from 'react-google-recaptcha';
import { Auth } from "./../../sdk"
import { useAuthentication } from './../../customHooks';
import { FaceBookSocialLogin, GoogleSocialLogin } from '../DefaultComponents';
import { TermsAndCondition } from './termsandcondition';
import { useNavigate } from 'react-router-dom';

export const Register = () => {
    let navigate = useNavigate();
    const [afterAuthentication] = useAuthentication();
    let [state, setState] = useState({
        userData: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            userType: "company",
            recaptcha: false,
            termsAndCondidtionCheck: false,

        },
        errors: {
            firstName: [],
            lastName: [],
            email: [],
            password: [],
            userType: [],
            recaptcha: [],
            termsAndCondidtionCheck: [],
            other: [],

        },
        validity: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            userType: "",
            recaptcha: "",
            termsAndCondidtionCheck: "",
        },
        buttonDisable: true,
        passwordStrength: {
            veryWeak: { background: "" },
            weak: { background: "" },
            strong: { background: "" },
            veryStrong: { background: "" }
        },
        passwordType: "password",
        submit: false,
        loader: false


    });
    const onSubmit = async (event) => {
        event.preventDefault();
        setState((pre) => {
            return { ...pre, loader: true }
        })
        try {
            if (event.target.getAttribute("submit-type") === "register") {
                let valid = await Validate.register.validate(state.userData, { abortEarly: false });
                let result = await new Auth().register(valid);
                afterAuthentication(result);
                navigate("/")
                Modal.showTab("#successPopup");
            }
        } catch (e) {
            let obj = {
                firstName: [],
                lastName: [],
                email: [],
                password: [],
                userType: [],
                recaptcha: [],
                termsAndCondidtionCheck: [],
                other: []
            }
            let validity = {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                userType: "",
                recaptcha: "",
                termsAndCondidtionCheck: "",
            }

            if (e.errors && e.errors.length > 0) {
                for (let error of e.errors) {
                    if (typeof (error) === "string") {
                        obj["password"].push(error);
                        validity["password"] = "error-class";
                    }
                    else {
                        obj[Object.keys(error)[0]].push(Object.values(error)[0]);
                        validity[[Object.keys(error)[0]]] = "error-class";
                    }

                }

            }
            else if (e.response) {
                let keys = Object.keys(e.response.data);
                obj[keys[0]] = e.response.data[keys[0]]
                validity[keys[0]] = "error-class";
            }
            else {
                obj.other.push("Error Occur! Please Contact your Adminstration");

            }
            setState((pre) => {
                return { ...pre, errors: obj, validity: validity, submit: true, loader: false }
            })

        }
    }
    const onHandler = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        let newData = { ...state.userData, [key]: value };
        let oldPasswordStrength = state.passwordStrength;
        let oldValidity = state.validity;
        if (key === "password") {
            oldPasswordStrength = new passwordStrength(value).checkPassword();
        }
        let newValidity = (new dataValidation(key, value, state.submit)).validate() ? { ...oldValidity, [key]: "" } : { ...oldValidity, [key]: "error-class" };

        setState((pre) => {
            return { ...pre, userData: newData, passwordStrength: oldPasswordStrength, validity: newValidity };
        })
    }
    const termsAgree = (event,button=false) => {
        let oldState = {...state};
        if(button){
            oldState.userData.termsAndCondidtionCheck = false;
        }
        if (!oldState.userData.termsAndCondidtionCheck) {
            oldState.userData.termsAndCondidtionCheck = true;
            if (oldState.userData.recaptcha) {
                oldState.buttonDisable = false;
            }
        }
        else {
            oldState.userData.termsAndCondidtionCheck = false;
            oldState.buttonDisable = true;
        }
        
        setState((pre) => {
            return { ...pre, ...oldState }
        })
    }
    const reCaptchaVerify = (data) => {
        let oldState = state;
        oldState.userData.recaptcha = true;
        if (state.userData.termsAndCondidtionCheck) {
            oldState.buttonDisable = false;
        }
        setState((pre) => {
            return { ...pre, oldState }
        })
    }
    return (<>
        <div className="modal fade join_form_popup show" style={{ display: "block" }} id="joinCommunity" tabIndex="-1" aria-labelledby="joinCommunityLabel" aria-modal="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <form onSubmit={onSubmit} submit-type="register">
                    <div className="container">
                            <div className="row justify-content-end">
                                <div className="col-lg-6">
                                    <div className="joinus_form">
                                        <div className="modal-header justify-content-center px-0 border-0">
                                            <h2 className="modal-title" id="joinCommunityLabel">Join Our Community
                                            </h2>
                                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                        </div>
                                        <div className="modal-body px-0">
                                            {/* <!-- Join As Options --> */}
                                            <div className="join-as d-flex justify-content-center">
                                                {state.errors.other.map((val, index) => {
                                                    return (<>
                                                        <p className='form_error'>{val}</p>
                                                    </>)
                                                })}
                                                <div className="form-check me-md-3">
                                                    <input className="form-check-input" type="radio" name="userType" id="inlineRadio1" onChange={onHandler} value="company" defaultChecked={state.userData.userType === "company" ? true : false} required />
                                                    <label className="form-check-label" htmlFor="inlineRadio1">As a Company</label>
                                                </div>
                                                <div className="form-check ms-md-3">
                                                    <input className="form-check-input" type="radio" name="userType" id="inlineRadio2" value="user" onChange={onHandler} defaultChecked={state.userData.userType === "user" ? true : false} required />
                                                    <label className="form-check-label" htmlFor="inlineRadio2">As a User</label>
                                                </div>
                                            </div>
                                            {/* <!-- Sign Up With Social Accounts --> */}
                                            <div className="signup-social row justify-content-between">
                                                <div className="col-md-6">
                                                    <FaceBookSocialLogin userType={{ "userType": state.userData.userType }} name="Sign Up With Facebook" type="register"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <GoogleSocialLogin userType={{ "userType": state.userData.userType }} name="Sign Up With Google" type="register"/>
                                                </div>
                                            </div>
                                            {/* <!-- OR --> */}
                                            <p className="or">OR</p>
                                            <hr />
                                            <div className="with-email">
                                                <h3>Sign Up with Email</h3>

                                                <div className="row justify-content-between">
                                                    <div className="col-md-6 mb-4">
                                                        <label htmlFor="fName" className="form-label">First Name</label>
                                                        <input type="text" className={`form-control ${state.validity.firstName}`} id="fName" onChange={onHandler} value={state.userData.firstName} name="firstName" placeholder="Sophía" required />

                                                        {state.errors.firstName.map((val, index) => {
                                                            return (<>
                                                                <p className='form_error'>{val}</p>
                                                            </>)
                                                        })}
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <label htmlFor="lName" className="form-label">Last Name</label>
                                                        <input type="text" className={`form-control ${state.validity.lastName}`} id="lName" name="lastName" onChange={onHandler} value={state.userData.lastName} placeholder="Belli" required />
                                                        {state.errors.lastName.map((val, index) => {
                                                            return (<>
                                                                <p className='form_error'>{val}</p>
                                                            </>)
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="email" className="form-label">Email address</label>
                                                    <input type="email" className={`form-control ${state.validity.email}`} id="email" name="email" onChange={onHandler} value={state.userData.email} placeholder="sophíabelli@websitename.com" required />
                                                    {state.errors.email.map((val, index) => {
                                                        return (<>
                                                            <p className='form_error'>{val}</p>
                                                        </>)
                                                    })}
                                                </div>
                                                <div className="mb-4 password">
                                                    <label htmlFor="pwd" className="form-label">Password</label>
                                                    <div className="position-relative">
                                                        <input type={state.passwordType} className={`form-control ${state.validity.password}`} id="pwd" name="password" onChange={onHandler} value={state.userData.password} placeholder="Enter Password" required />

                                                        <button type="button" className="btn p-0 pwd-btn"><i className="fas fa-eye-slash" onClick={() => {
                                                            if (state.passwordType === "password") {
                                                                setState((pre) => {
                                                                    return { ...pre, passwordType: "text" }
                                                                })
                                                            }
                                                            else {
                                                                setState((pre) => {
                                                                    return { ...pre, passwordType: "password" }
                                                                })
                                                            }
                                                        }}></i></button>
                                                    </div>
                                                    {state.errors.password.map((val, index) => {
                                                        return (<>
                                                            <p className='form_error'>{val}</p>
                                                        </>)
                                                    })}
                                                    <div className="pwd-power d-flex justify-content-between">
                                                        <span style={state.passwordStrength.veryWeak}></span>
                                                        <span style={state.passwordStrength.weak}></span>
                                                        <span style={state.passwordStrength.strong}></span>
                                                        <span style={state.passwordStrength.veryStrong}></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer align-items-center flex-wrap border-0 px-0">
                                            <div className="col-md-6 recaptcha d-flex justify-content-between align-items-center">
                                                {/* <!-- Recaptcha --> */}
                                                <Recaptcha

                                                    elementid="g-recaptcha"
                                                    render="onload"
                                                    theme='light'
                                                    type='image'
                                                    sitekey={process.env.REACT_APP_reCAPTCHA_SITE_KEY}
                                                    onChange={reCaptchaVerify}
                                                    size="normal"

                                                />
                                                {state.errors.recaptcha.map((val, index) => {
                                                    return (<>
                                                        <p className='form_error'>{val}</p>
                                                    </>)
                                                })}
                                            </div>

                                            {/* <div className="col-md-6 recaptcha d-flex justify-content-between align-items-center">
                                    <div className="form-check d-flex align-items-center">
                                        <input className="form-check-input" type="checkbox" value="" id="robot" required />
                                        <label className="form-check-label" htmlFor="robot">
                                            I'm not a robot
                                        </label>
                                    </div>
                                    <img src="assets/images/google_recaptcha.svg" alt="Google Recaptcha Logo" />
                                     
                                </div> */}

                                            {/* <!-- Continue Btn --> */}
                                            <div className="col-md-6">
                                                <button type="submit" className="btn site-btn" disabled={state.buttonDisable}><span>Continue  {(state.loader) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span></button>
                                            </div>
                                            <div className="col-12 termsCheck d-flex justify-content-center">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" onChange={termsAgree} id="terms" checked={state.userData.termsAndCondidtionCheck} />
                                                    <label className="form-check-label" htmlFor="terms">
                                                        I agree to <a type='button' data-bs-toggle="modal" data-bs-target="#termsPopup">Terms and Conditions</a>
                                                    </label>
                                                    {state.errors.termsAndCondidtionCheck.map((val, index) => {
                                                        return (<>
                                                            <p className='form_error'>{val}</p>
                                                        </>)
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                    </form>
                </div>
            </div>
        </div>
        <TermsAndCondition agreeTerms={termsAgree} />
    </>
    )
}