import React,{useState,} from 'react';
import { Link } from 'react-router-dom';
import LikeBtnContainer from './LikeBtnContainer';
import LikerImg from './LikerImg';
import PostDetailControls from './PostDetailControls';
import PostedImg from './PostedImg';
import PostedVideo from './PostedVideo';
import * as moment from "moment"
import { Collection } from '../popup/Collection';
import { Resources } from '../popup/Resources';
import { Modal } from '../../helpers';
import axios from 'axios';
const UserResource = (props) => {

const onClick=(url,type)=>{
  axios(url, {
        method: 'GET',
        responseType: 'blob',
    }).then((response)=>{
        const file = new Blob(
            [response.data], 
            {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
           window.open(fileURL);
       
    }).catch((e)=>{})
}
console.log(props.data);
return (

<>
{props.data.map((val,index)=>{
 
    return (<>
    <div className={`public_posts resource_posts col-md-6 col-6`} >
        <div className={`post_container`}>
            <div className={`post-visual`}>
          {(val.resourceType==="video" && <PostedVideo postVideoSrc={val.media[1]} postVideoImg={val.media[0]} />) }
          {val.resourceType==="pdf" && <img src='assets/images/pdf.png' alt='' className='doc_img' style={{maxHeight:"220px"}} onClick={()=>onClick(val.media[0],"pdf")}/>}
          {val.resourceType==="word" && <img src='assets/images/docx.png' alt='' className='doc_img' />}
            </div>
           
            <div className={`post-bottom pt-0`}>
            <div className={`post-controls d-flex flex-wrap align-items-center`}>
                    
                    {/* <div className="liker_icon d-flex align-items-center">
                        {val.likes.map((val2) => {
                            return (
                                <LikerImg
                                    key={val2.id}
                                    likeUserImg={(val2.profile_pic)?val2.profile_pic:"assets/images/avatar.jpg"}
                                    
                                />
                            );
                        })}
                        <p className='count_likes'>+{val.likes.length}</p>
                    </div> */}
                    
                    {/* <LikeBtnContainer myProfile={props.myProfile}  type={props.type} parentState={props.parentState} data={val.likes} post_id={val._id}/> */}
                     
                    <div className="post-action-comment">
                    <Link to={`/resource-detail/${val._id}/${props.parentId}`} className='btn comment-btn p-0 lh-1' >{val.title}</Link>
                        
                    </div>
                  {/* <div className="post-action-collection">
                        <button className="btn p-0 lh-1 collection-btn" onClick={(e)=>onClickTarget(e,val._id,"#selectCollection")}>
                            <i className="far fa-plus-circle"></i>
                            <span className='d-none d-md-inline-block'>Add to</span> Collection
                        </button>
                    </div>
                  {props.type ==="details" && <PostDetailControls postTime={props.postTime} />} */}
                </div>
            </div>
        </div>
        </div>
    </>);
   
  
   })}

   
   
</>)


}

export default UserResource;