import { useState } from "react"
import { toasterFunction } from "../DefaultComponents"
import { Auth } from "./../../sdk"
export const VerifyEmail = () => {
    const [state, setState] = useState(false);
    const sendEmail = async () => {
        try {
            setState(true);
            await new Auth().resendEmail();
            toasterFunction.success("Email has been resend, Please Verify")
            setState(false);
        } catch (e) {
            toasterFunction.error("Problem Occur! Please Try Again");
            setState(false);
        }
    }
    return (<>
        <div className="modal fade success_popup" id="successPopup" tabIndex="-1" aria-labelledby="successPopupLabel" aria-hidden="true" >
            <div className="modal-dialog">
                <div className="modal-content">
                    <form>
                        <div className="success_content">
                            <div className="modal-header justify-content-center border-0">
                                <h2 className="modal-title" id="successPopupLabel">Verify Email</h2>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center">
                                <img src="assets/images/success_img.svg" alt="Email Successfully Sent" />
                                <p>We have sent you a verification email on your entered email address please check your inbox</p>
                            </div>
                            <div className="modal-footer justify-content-center border-0">
                                <button type="button" className="btn site-btn w-100" disabled={state} onClick={sendEmail}><span>Re Send Email {(state) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>)
}