import React,{useState} from 'react';
import { useUpdateProfile } from '../../customHooks';
import { Validate } from '../../helpers';
import { User } from '../../sdk';
import { Loader } from '../DefaultComponents';

const CoverImgUploader = (props) => {
    const [state,setState]=useState({
        buttonLoader:false,
        buttonDisable:false,
        firstName:props.userData.firstName,
        lastName:props.userData.lastName,
        username:props.userData.username,
        loader:false,
        
    })
    const [updateProfile]=useUpdateProfile();
    const onHandler=async(event)=>{
      try{
        // if (event.target.files[0].size > 1 * (1024 * 1024)) {
        //     alert("image size maximum 1 mb");
        //     return false;
        // }
          let valid;
          if(event.target.name==="profile_pic"){
            valid=await Validate.updateProfile.validate({...state,profile_pic:event.target.files[0]});
          }
          else{
            valid=await Validate.updateProfile.validate({...state,cover_pic:event.target.files[0]});
          }
          setState((pre)=>{
            return {...pre,loader:true}
           })
        let result=await new User().updateProfile(valid);
        updateProfile(result);
        setState((pre)=>{
            return {...pre,buttonLoader:false,buttonDisable:false,loader:false}
           })

      }catch(e){
        setState((pre)=>{
          return {...pre,loader:false}
         })
      }
    }
    return (<>
     <section className='profile_cover'>
     <img src={(props.userData.cover_pic)?props.userData.cover_pic:"assets/images/profile_cover.png"} alt="Cover Img" className='main' />
     {props.myProfile &&  <div className="change_cover">
            <label htmlFor="cover_img_uploader" className='mb-0 position-relative d-flex align-items-center'>
                <input type="file" accept='image/*' name='cover_img_uploader' onChange={onHandler} id='cover_img_uploader' className='uploader_input' disabled={state.buttonDisable}/>
                <img src="assets/images/cover_img_icon.svg" alt="Cover Img Icon" />
                <span>Change Cover Image</span>
                
            </label>
           
        </div>}
       
        </section>
        <div className="profile_img_uploader">
            <div className="uploader d-inline-block position-relative">
                <img src={props.userData.profile_pic?props.userData.profile_pic:"assets/images/profile_silhotte.svg"} alt="Profile Img" className='profile' />
                {props.myProfile &&  <button className='btn p-0 shadow-none'>
                    <input className='uploader_input' type="file" name="profile_pic" id="ProfileImgUploader" accept='image/*' onChange={onHandler} />
                    <img src="assets/images/uploader_icon.svg" alt="Img Uploadern Icon" />
                </button> }
                
               
            </div>
           
        </div>
        {state.loader && <Loader/>}
        
    </>);
}

export default CoverImgUploader;
