
import { Own_User,My_Post_Action,Other_Post_Action } 
         from "../ActionConstants"
import { getFriendShipStatus } from "../helper"
import { store } from "../store"
export const OwnUserUpdate=(data)=>{
  if(data){
   let friendShipStatus=getFriendShipStatus(data,data.id)
   
    return {
         type:Own_User.success,
         payload:{data,friendShipStatus}
     }
  }
  else{
    return {
      type:Own_User.success,
      payload:{data,friendShipStatus:{
        followers:[],
        followings:[],
        followBack:[],

      }}
  }
  }
  
}

export const OwnUserUpdateByKey=(key,newData,type="add")=>{
        let {data,friendShipStatus}=(store.getState()).ownUser;
        
        if(type==="add"){
          let newState={...data,[key]:newData};
          if(key==="followers" || key==="followings"){
            friendShipStatus=getFriendShipStatus(newState,data.id)
          }
          return {
            type:Own_User.success,
            payload:{data:newState,friendShipStatus}
        }
        }
        if(type==="push"){
          data[key].push(newData);
          return {
            type:Own_User.success,
            payload:{data:data,friendShipStatus}
        }
        }
        if(type==="update"){
          data[key]=data[key].map((val)=>{
            if(val._id===newData._id){
              return newData;
            }
            return val;
          })
          return {
            type:Own_User.success,
            payload:{data:data,friendShipStatus}
        }
        }
        if(type==="remove"){
          data[key]=data[key].filter(val=>val._id !==newData._id)
          return {
            type:Own_User.success,
            payload:{data:data,friendShipStatus}
        }
        }
    
   
 }
 export const postAction=(newData,myProfile=false)=>{
  let data="";
  let action="";
  if(myProfile){
    data=(store.getState()).ownUser.data;
    action=My_Post_Action.success;

  }
  else{
    data=(store.getState()).user.data;
    action=Other_Post_Action.success;
  }

  let newArr=data.posts.map((val)=>{
    if(val._id===newData._id){
      return newData;
    }
    else{
      return val;
    }
  })
  let newState={...data,posts:newArr}
    return {
      type:action,
      payload:{data:newState}
  
  }


}