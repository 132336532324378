import { Get_User,Other_Post_Action } from "../ActionConstants"
let intialState={
    loading:true,
    data:null,
    error:null,
}
export const getUserReducer=(state=intialState,{type,payload})=>{
switch (type) {
    case Get_User.success:
    return {...state,data:payload,loading:false}
    case Other_Post_Action.success:
    return {...state,data:payload.data}
    case Get_User.error:
    return {...state,error:payload,loading:false}
    default:
    return state
}
}