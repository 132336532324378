
export const Modal={
     showTab:(id)=>{
      window.$(document).ready(function(){
        window.$(id).modal("show");
        })
    
    },
    hideTab:(id)=>{
        window.$(document).ready(function(){
            window.$(".modal-backdrop").remove();
            window.$(id).modal("hide");
        })
    }
}