import {Navigate,Outlet} from "react-router-dom";

import { Storage } from "../helpers";
export const PrivateRoutes = ({component: Component, ...rest}) => {
let getCookies=Storage.getCookie(`${process.env.NODE_ENV}-userToken`);    
if(!getCookies){
    Storage.removeLocalStorage(`${process.env.NODE_ENV}-user`);
}
return getCookies?<Outlet/>:<Navigate to="/public"/>
};
