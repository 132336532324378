export let urlRoutes={
    unAuthorize:{
        landing:"/",
        aboutUs:"/about-us",
        privacyPolicy:"/privacy-policy",
        howItsWork:"/howitswork",
        termsAndCondition:"/terms-and-condition",
        categories:"/categories",
        home:"/home",
        company:"/company",
        generatePassword:"/generatePassword",
        resetPassword:"/resetPassword"
    },
    authorize:{
       
        completeProfile:"/complete-profile",
        userProfile:"/user-profile",
        profile:"/profile",
        settings:"/settings",
        postDetails:"/postdetails/:id",
        changeEmail:"/changeEmail",
        
    }
}