import React from 'react';
import { Link } from 'react-router-dom';

const Footer = (props) => {
    return (<>
        {/* <!-- site Footer --> */}
        <footer>
            <div className="site-footer">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-3 logo">
                        <Link to='/'>
                            <img src="assets/images/site-logo.svg" alt="Brand Logo" />
                        </Link>
                        </div>
                        <div className="col-md-3 footer-links">
                            <h3>Useful Links</h3>
                            <Link to="/#">About us</Link>
                            <Link to="/#">How it works</Link>
                        </div>
                        <div className="col-md-3 footer-links">
                            <h3 className="op-0 d-none d-md-block">Useful Links</h3>
                            <Link to="/terms">Terms of Services</Link>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </div>
                        <div className="col-md-3 footer-links">
                            <h3 className="op-0 d-none d-md-block">Useful Links</h3>
                            <Link to="/contact">Contact us</Link>
                            <div className="social d-flex">
                                <a href="/#"><i className="fab fa-facebook-f"></i></a>
                                <a href="/#"><i className="fab fa-instagram"></i></a>
                            </div>
                        </div>
                        <div className="col-12 footer-bar text-center">
                            Copyright 2022 all rights are reserved
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>);
}

export default Footer;