import Multiselect from 'multiselect-react-dropdown';
import { useState,useEffect } from 'react';

import { Post } from '../../sdk';
import { toasterFunction } from '../DefaultComponents';
import { useUpdateProfile } from '../../customHooks';


export const Resources=({postId})=>{
  const [updateProfile]=useUpdateProfile();

let userResource=[];
let selectedValues=[];
let resourceIds=[]
// let resourceIds=userResource.map((val)=>{
//     if(val.posts.length){
//         for(let post of val.posts){
//             if(post._id===postId){
//                 selectedValues.push(val);
//                 return val._id;
//             }
//         }
//     }
//  });
 
const [state,setState]=useState({
        postId:"",
        resourceIds:[],
        selectItem:[],
});
useEffect(()=>{
    setState((pre)=>{
        return {...pre, postId:postId,
            resourcesIds:resourceIds,
            selectItem:selectedValues}
    })
    },[postId])
const addToResource=async()=>{
    try{
      let result=await new Post().addToCollectionOrResource(state);
      updateProfile(result);
      toasterFunction.success("data has been updated");
    }catch(e){
        toasterFunction.error("error Occur ! Please Try Again")
    }
}

return (<>
        <div className="modal fade join_form_popup" id="selectResource" tabIndex="-1" aria-labelledby="joinCommunityLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                   
                        <div className="joinus_form">
                            <div className="modal-header justify-content-center border-0">
                                <h2 className="modal-title" id="joinCommunityLabel">Select Resources
                                </h2>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            <div className="post-field-collect row align-items-end">
                <div className="col-md-9">
                <label htmlFor="postCollections">Resources</label>
            <Multiselect
            displayValue="name"
            customCloseIcon={<><i className='fal fa-times'></i></>}
            onKeyPressFn={function noRefCheck() { }}
            onRemove={function noRefCheck(value,select) { 
                let selectItem=state.selectItem.filter((val)=>{
                    return val._id !==select._id
                })
                let resourcesIds=state.resourceIds.filter((val)=>{
                    return val !==select._id;
                })
                setState((pre)=>{
                    return {...pre,selectItem:selectItem,resourceIds:resourcesIds}
                })
            }}
            onSearch={function noRefCheck() { }}
            onSelect={function noRefCheck(value,select) {
                state.selectItem.push(select);
                state.resourceIds.push(select._id);
                setState((pre)=>{
                    return {...pre,...state}
                })
            }}
            selectedValues={state.selectItem}
            placeholder={"select Resource"}
            options={userResource}
            showCheckbox
            avoidHighlightFirstOption={true}
        />
            </div>
            {/* <div className="col-md-3">
                <button className='btn site-btn' type='button'  id="#createCollection" onClick={(e,id="#createCollection")=>{
                Modal.hideTab(".modal");
                  Modal.showTab(id);
                }}><span>Create New Collection</span></button>
            </div> */}
        </div>
        </div> 
        </div>
        <div className="modal-footer align-items-center flex-wrap border-0">
                            <div className="col-md-6">
                            <button type="submit" className="btn site-btn" onClick={addToResource}  disabled={state.buttonDisable}><span>Continue  {(state.loader)?<div className="spinner-border"style={{width:"1rem",height:"1rem"}}></div>:""}</span></button>
                            </div>
                               
                            </div>
                        </div>
                 
                </div>
            </div>
        
        </>
        )
}