import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../context';
import FilterItem from '../Filters/FilterItem';
import { passwordStrength } from './../../helpers';
import ProfileImgUploader from './ProfileImgUploader';
import { useQuery, useUpdateProfile } from '../../customHooks';
import { Validate } from '../../helpers';
import { User, Categories } from '../../sdk';
import { toasterFunction } from '../DefaultComponents';
import { selector, dispatch as parentDispatch } from '../../redux';
import { OwnUserUpdateByKey } from '../../redux/Actions';
import { GeneratePassword } from '../popup';
import { useNavigate } from 'react-router-dom';

const ProfileSettings = () => {
    const user = selector(state1 => state1.ownUser);
    const param=useQuery();
    let token=param.get("token");
    let userId=param.get("userId");
    const navigate=useNavigate()
    const userIntersts = () => {
        let arr = user.data.intersts;
        let interstIds = arr.map((val) => {
            return val._id
        })

        return interstIds
    }
    const userAuth = useAuthContext();
    const [updateProfile] = useUpdateProfile();
    let profileTab = {
        profileStep1: true,
        profileStep2: false,
        profileStep3: false,
        profileStep4: false
    }
    if (window.localStorage.getItem("tab")) {
        profileTab = {
            profileStep1: false,
            profileStep2: true,
            profileStep3: false,
            profileStep4: false
        }
        window.localStorage.removeItem("tab")
    }
    const [state, setState] = useState({
        profileTab: profileTab,
        resetPopUp:false,
        formData: {
            profileStep1: {
                profile_pic: {},
                showProflePic: (userAuth.user.profile_pic && userAuth.user.profile_pic !== null) ? userAuth.user.profile_pic : "assets/images/profile_silhotte.svg",
                about: userAuth.user.about,
                website_url: userAuth.user.website_url,
                username: userAuth.user.username,
                email: userAuth.user.email,
                firstName: userAuth.user.firstName,
                lastName: userAuth.user.lastName,
                oldpassword: "",
                newpassword: ""

            },
            profileStep2: {
                notificationSettings: userAuth.user.notificationSettings ? userAuth.user.notificationSettings : {
                    userFollow: 1,
                    userSaveImageCollection: 1,
                    likeImage: 1,

                }
            },
            profileStep3: {
                userIntersts: userIntersts()
            },
            profileStep4: {
                followers: []
            }
        },
        formDataError: {
            profileStep1: {
                profile_pic: [],
                about: [],
                website_url: [],
                email: [],
                oldpassword: [],
                newpassword: [],
                username: [],
                other: [],
                firstName: [],
                lastName: [],

            }
        },
        passwordStrength: {
            veryWeak: { background: "" },
            weak: { background: "" },
            strong: { background: "" },
            veryStrong: { background: "" }
        },
        currentPasswordType: "password",
        newPasswordType: "password",
        activeTab: 1,
        loader: true,
        error: false,
        buttonLoader: false,
        buttonDisabled: false,
        errorText: "",
        interests: [],
        modalData: {
            changeEmailData: {
                formData: {
                    email: "",
                    password: "",
                },
                error: {
                    email: [],
                    password: [],
                    other: []
                },
                passwordType: "password",
                passwordStrength: {
                    veryWeak: { background: "" },
                    weak: { background: "" },
                    strong: { background: "" },
                    veryStrong: { background: "" }
                },
            }

        }
    })
    
    const dispatch = parentDispatch();
    useEffect(() => {
        let obj = {
            showProflePic: (userAuth.user.profile_pic && userAuth.user.profile_pic !== null) ? userAuth.user.profile_pic : "assets/images/profile_silhotte.svg",
            about: userAuth.user.about,
            website_url: userAuth.user.website_url,
            username: userAuth.user.username,
            email: userAuth.user.email,
            firstName: userAuth.user.firstName,
            lastName: userAuth.user.lastName,
        }
        state.formData.profileStep1 = obj;
        state.formData.profileStep2.notificationSettings = userAuth.user.notificationSettings;
        if(token && userId){
            new User().verfiyEmailForChangePassword({token:token,userId:userId}).then((data)=>{
              state.resetPopUp=true;
              setState((pre) => {
                return { ...pre, ...state, loader: false }
            })
            }).catch((error)=>{
               
                toasterFunction.error("link may be expired or not authorize !Please Try again");
                navigate("/");
             
            })
           }
           else{
            setState((pre) => {
                return { ...pre, ...state, loader: false }
            })
           }
       
    }, [])
  
       

    const tabToggle = async (currentIndex, prev = 0) => {
        let toggleState = {
            profileStep1: false,
            profileStep2: false,
            profileStep3: false,
            profileStep4: false
        }
        setState((pre) => {
            return { ...pre, loader: true };
        })
        let activeTab = (currentIndex + 1) + prev;
        toggleState[`profileStep${activeTab}`] = true;
        let intersts = [];
        if (activeTab === 3) {
            try {
                intersts = await getCategories();
                setState((pre) => {
                    return { ...pre, profileTab: toggleState, activeTab: activeTab, interests: intersts, loader: false };
                })
            } catch (e) {
                setState((pre) => {
                    return { ...pre, profileTab: toggleState, activeTab: activeTab, interests: intersts, loader: false };
                })
            }
        }
        else {
            setState((pre) => {
                return { ...pre, profileTab: toggleState, activeTab: activeTab };
            })
        }

    }
    const onModalSubmit = async (e) => {
        e.preventDefault();
        try {
            setState((pre) => {
                return { ...pre, buttonLoader: true, buttonDisable: true }
            })
            let valid = await Validate.changeEmail.validate(state.modalData.changeEmailData, { abortEarly: false });
            await new User().changeEmail(valid);
            toasterFunction.success("email has been send please check your email for verfication");
            setState((pre) => {
                return { ...pre, buttonLoader: false, buttonDisable: false }
            })
        } catch (e) {
            let obj = {
                email: [],
                password: [],
                other: []
            }
            if (e.errors && e.errors.length > 0) {
                for (let error of e.errors) {
                    if (typeof (error) === "string") {
                        obj["password"].push(error);

                    }
                    else {
                        obj[Object.keys(error)[0]].push(Object.values(error)[0]);

                    }

                }

            }
            else if (e.response) {
                let keys = Object.keys(e.response.data);
                obj[keys[0]] = e.response.data[keys[0]]
            }
            else {
                obj.other.push("Error Occur! Please Contact your Adminstration");
            }
            state.modalData.changeEmailData.error = obj;
            setState((pre) => {
                return { ...pre, ...state, buttonDisabled: false, buttonLoader: false };
            })

        }

    }
    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setState((pre) => {
                return { ...pre, buttonLoader: true, buttonDisable: true }
            })

            if (state.activeTab === 1) {
                if (Object.values(state.formData.profileStep1).length > 0) {
                    if (state.formData.profileStep1.newpassword) {
                        let regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,64})");
                        if (!regex.test(state.formData.profileStep1.newpassword)) {
                            state.formDataError.profileStep1.newpassword.push("Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character");
                            setState((pre) => {
                                return { ...pre, ...state };
                            })
                            return false;
                        }
                    }
                    let valid = await Validate.updateProfile.validate(state.formData.profileStep1, { abortEarly: false });
                    let result = await new User().updateProfile(valid);
                    toasterFunction.success("Setting has been changed");
                    updateProfile(result);
                    setState((pre) => {
                        return { ...pre, buttonLoader: false, buttonDisable: false }
                    })
                }
            }
            else if (state.activeTab === 2) {
                let result = await new User().updateNotificationSettings(state.formData.profileStep2.notificationSettings);
                toasterFunction.success("Notification Setting has been changed");
                updateProfile(result);
                window.localStorage.setItem("tab",true);
                
                setState((pre) => {
                    return { ...pre, buttonLoader: false, buttonDisable: false }
                })
            }
            else if (state.activeTab === 3) {
                let result = await new User().interestToggle(state.formData.profileStep3.userIntersts);
                dispatch(OwnUserUpdateByKey("intersts", result.intersts))
                toasterFunction.success("Interst has been updated")
                setState((pre) => {
                    return { ...pre, buttonLoader: false, buttonDisable: false }
                })
            }
            
            
        } catch (e) {
            console.log(e);
            
            let obj = {
                profile_pic: [],
                about: [],
                website_url: [],
                email: [],
                oldpassword: [],
                newpassword: [],
                username: [],
                other: [],
                firstName: [],
                lastName: []
            }
            if (e.errors && e.errors.length > 0) {
                for (let error of e.errors) {
                    console.log(error);
                    if (typeof (error) === "string") {
                        obj["newpassword"].push(error);

                    }
                    else {
                        obj[Object.keys(error)[0]].push(Object.values(error)[0]);

                    }

                }

            }
            else if (e.response) {
                let keys = Object.keys(e.response.data);
                obj[keys[0]] = e.response.data[keys[0]]
            }
            else {
                obj.other.push("Error Occur! Please Contact your Adminstration");
            }
            let newState = { ...state.formDataError, profileStep1: obj };
            setState((pre) => {
                return { ...pre, formDataError: newState, buttonLoader: false, buttonDisable: false }
            })

        }

    }
    const sendEmailForPasswordChange = async (e) => {
        e.preventDefault();
        try {
            setState((pre) => {
                return { ...pre, buttonLoader: true, buttonDisable: true }
            })

             await new User().sendEmailForChangePassword();
            toasterFunction.success("Email has been sent,please check your mail box");
            setState((pre) => {
                return { ...pre,buttonLoader: false, buttonDisable: false }
            })
            
        } catch (e) {
            console.log(e);
            toasterFunction.error(Object.keys(e.response.data)[0])
            setState((pre) => {
                return { ...pre,buttonLoader: false, buttonDisable: false }
            })

        }

    }
    const getCategories = async () => {
        try {
            let data = await new Categories().getCategories();
            return new Promise((res, rej) => {
                res(data);
            })
        } catch (e) {
            if (e.response.status === 404) {
                return new Promise((res, rej) => {
                    res([]);
                })
            }
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    const onHandler = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        let oldState = {...state.formData.profileStep1};
        let oldPasswordStrength = state.passwordStrength;
        if (key === "profile_pic") {
            if (event.target.files[0].size > 1 * (1024 * 1024)) {
                // let errors = {
                //     profile_pic: ["File Size not greater than 1 mb"],
                //     about: [],
                //     website_url: []
                // };
                // let newState = { ...state.formDataError, profileStep1: errors }
                // setState((pre) => {
                //     return { ...pre, formDataError: newState }
                // })
                alert("file size maximum 1 mb");
                return false;
            }
          
            oldState = { ...oldState,profile_pic: event.target.files[0], showProflePic: URL.createObjectURL(event.target.files[0])}
           
            
        }
        else if (key === "newpassword") {
            oldPasswordStrength = new passwordStrength(value).checkPassword();
            oldState = { ...oldState, [key]: value }
        }
        else{
            oldState = { ...oldState, [key]: value }
        }
       let newState = { ...state.formData, profileStep1: oldState }
        setState((pre) => {
            return { ...pre, formData: newState, passwordStrength: oldPasswordStrength }
        })

    }
    const modalHandler = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        let oldPasswordStrength = state.modalData.changeEmailData.passwordStrength;
        if (key === "password") {
            oldPasswordStrength = new passwordStrength(value).checkPassword();

        }
        
        let oldData = { ...state.modalData.changeEmailData, [key]: value, passwordStrength: oldPasswordStrength };
        let newState = { ...oldData, changeEmailData: oldData };
        setState((pre) => {
            return { ...pre, modalData: newState }
        })

    }
    const notificationToggle = (event) => {
        let key = event.target.name;
        if (state.formData.profileStep2.notificationSettings[key]) {
            state.formData.profileStep2.notificationSettings[key] = 0;
        }
        else {
            state.formData.profileStep2.notificationSettings[key] = 1;
        }
        setState((pre) => {
            return { ...pre, ...state }
        })
    }
    console.log(state.resetPopUp);
    return (<>
    {state.resetPopUp && <GeneratePassword userId={userId} parentState={setState}/>}
    {/* <GeneratePassword userId={userId} parentState={setState}/> */}
        <section className='profile_settings'>
            <div className="container">
                <div className="wrapper">
                    {/* Section Title */}
                    <div className="section-title text-md-center">
                        <h2>Settings</h2>
                    </div>
                    {/* Tabbing Section */}
                    <div className="profile_tabbing mt-5">
                        <ul className="nav nav-tabs justify-content-md-center" id="profileSettingsTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${state.profileTab.profileStep1 ? 'active' : ''}`} id="profileAccount_Btn" type="button" role="tab" aria-controls="profileAccount_Tab" aria-selected="true" onClick={() => tabToggle(1, -1)}>
                                    Account
                                    <svg xmlns="http://www.w3.org/2000/svg" width="108" height="8" viewBox="0 0 108 8"><path d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" fill="" /></svg>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${state.profileTab.profileStep2 ? 'active' : ''}`} type="button" role="tab" aria-controls="profileNoti_Tab" aria-selected="true" onClick={() => tabToggle(2, -1)}>
                                    Notification
                                    <svg xmlns="http://www.w3.org/2000/svg" width="108" height="8" viewBox="0 0 108 8"><path d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" fill="" /></svg>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${state.profileTab.profileStep3 ? 'active' : ''}`} id="profileInterest_Btn" type="button" role="tab" aria-controls="profileInterest_Tab" aria-selected="true" onClick={() => tabToggle(3, -1)}>
                                    Interests
                                    <svg xmlns="http://www.w3.org/2000/svg" width="108" height="8" viewBox="0 0 108 8"><path d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" fill="" /></svg>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${state.profileTab.profileStep4 ? 'active' : ''}`} id="profilePrivacy_Btn" type="button" role="tab" aria-controls="profilePrivacy_Tab" aria-selected="true" onClick={() => tabToggle(4, -1)}>
                                    Privacy
                                    <svg xmlns="http://www.w3.org/2000/svg" width="108" height="8" viewBox="0 0 108 8"><path d="M90,8a14.9,14.9,0,0,1-9.557-3.248,13.721,13.721,0,0,0-16.888,0A14.9,14.9,0,0,1,54,8a14.9,14.9,0,0,1-9.556-3.248A13.08,13.08,0,0,0,36,1.846a13.079,13.079,0,0,0-8.443,2.906A14.894,14.894,0,0,1,18,8,14.894,14.894,0,0,1,8.443,4.752,13.078,13.078,0,0,0,0,1.846V0A14.9,14.9,0,0,1,9.556,3.248,13.078,13.078,0,0,0,18,6.154a13.079,13.079,0,0,0,8.443-2.906A14.9,14.9,0,0,1,36,0a14.9,14.9,0,0,1,9.556,3.248A13.08,13.08,0,0,0,54,6.154a13.078,13.078,0,0,0,8.443-2.906,15.684,15.684,0,0,1,19.113,0A13.083,13.083,0,0,0,90,6.154c4.194,0,6.258-1.412,8.445-2.906A14.9,14.9,0,0,1,108,0V1.846a13.083,13.083,0,0,0-8.445,2.906A14.9,14.9,0,0,1,90,8" fill="" /></svg>
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="profileSettingsTabContent">
                            <div className={`tab-pane fade show active ${state.profileTab.profileStep1 ? '' : 'd-none'}`} id="profileAccount_Tab" role="tabpanel" aria-labelledby="profileAccount_Btn">
                                <div className="tab-wrapper">
                                    <form onSubmit={onSubmit}>
                                        {/* Profile Img Uploader */}
                                        <ProfileImgUploader value={state.formData.profileStep1} setValue={onHandler} error={state.formDataError.profileStep1.profile_pic} />
                                        <div className="row">
                                            {/* First Name */}
                                            <div className="col-md-6">
                                                <div className="post-field-fname">
                                                    <label htmlhtmlFor="fname">First Name</label>
                                                    <input type="text" name="firstName" value={state.formData.profileStep1.firstName} minLength={3} id="fname" className="form-control" placeholder="John" onChange={onHandler} required />
                                                    {state.formDataError.profileStep1.firstName.map((val, index) => {
                                                        return (<>
                                                            <p className='form_error'>{val}</p>
                                                        </>)
                                                    })}
                                                </div>
                                            </div>
                                            {/* Last Name */}
                                            <div className="col-md-6">
                                                <div className="post-field-lname">
                                                    <label htmlhtmlFor="lname">Last Name</label>
                                                    <input type="text" name="lastName" id="lname" value={state.formData.profileStep1.lastName} minLength={3} className="form-control" onChange={onHandler} placeholder="John" required />
                                                    {state.formDataError.profileStep1.lastName.map((val, index) => {
                                                        return (<>
                                                            <p className='form_error'>{val}</p>
                                                        </>)
                                                    })}
                                                </div>
                                            </div>
                                            {/* UserName */}
                                            <div className="col-12">
                                                <div className="post-field-username">
                                                    <label htmlhtmlFor="username">Username</label>
                                                    <input type="text" name="username" id="username" className="form-control" value={state.formData.profileStep1.username} minLength={3} onChange={onHandler} placeholder="johnsmiths" required />
                                                    {state.formDataError.profileStep1.username.map((val, index) => {
                                                        return (<>
                                                            <p className='form_error'>{val}</p>
                                                        </>)
                                                    })}
                                                </div>
                                            </div>
                                            {/* Tell Us ABout Yourself */}
                                            <div className="col-12">
                                                <div className="post-field-title">
                                                    <label htmlhtmlhtmlFor="tellAboutYou" className="d-flex justify-content-between align-items-center mb-3">
                                                        <span>Enter Bio</span>
                                                    </label>
                                                    <textarea className="form-control" id="tellAboutYou" name="about" value={state.formData.profileStep1.about} maxLength={500} onChange={onHandler} placeholder='Lead UI / UX Designer, ☕️Tea Addictive , 🛫Traveler, 🎵Music, 29th July My Day, Leo™' rows={7}>{state.formData.profileStep1.about}</textarea>
                                                    {state.formDataError.profileStep1.about.map((val, index) => {
                                                        return (<>
                                                            <p className='form_error'>{val}</p>
                                                        </>)
                                                    })}
                                                </div>
                                            </div>
                                            {/* Email Address */}
                                            <div className="col-md-6">
                                                <div className="post-field-email position-relative">
                                                    <label htmlhtmlFor="settingsEmail">Email Address</label>
                                                    <input type="email" id="settingsEmail" value={state.formData.profileStep1.email} className="form-control" onChange={onHandler} placeholder="johnsmiths@websitename.com" disabled />
                                                    <button className="btn p-0 lh-1 btn-email shadow-none" type='button' data-bs-toggle="modal" data-bs-target="#changeEmail">Change Email</button>

                                                </div>
                                            </div>
                                            {/* Website */}
                                            <div className="col-md-6">
                                                <div className="post-field-website">
                                                    <label htmlhtmlFor="settingsWebsite">Website</label>
                                                    <input type="text" id="settingsWebsite" name="website_url" value={state.formData.profileStep1.website_url} onChange={onHandler} className="form-control" placeholder="Enter Website" />
                                                    {state.formDataError.profileStep1.website_url.map((val, index) => {
                                                        return (<>
                                                            <p className='form_error'>{val}</p>
                                                        </>)
                                                    })}
                                                </div>
                                            </div>
                                            {/* Current Password */}
                                            {(userAuth.user.ispassword) && <><div className="col-md-6">
                                                <div className="post-field-password no_bars position-relative">
                                                    <label htmlhtmlFor="settingsPwd" className="form-label">Current Password</label>
                                                    <div className="position-relative">
                                                        <input type={state.currentPasswordType} className="form-control" id="settingsPwd" placeholder="Enter Current Password" value={state.formData.profileStep1.oldpassword} name="oldpassword" onChange={onHandler} required="" />

                                                        <button className="btn p-0 pwd-btn" type="button"><i className="fas fa-eye-slash" onClick={() => {
                                                            let passwordType = "password";
                                                            if (state.currentPasswordType === "password") {
                                                                passwordType = "text"
                                                            }
                                                            setState((pre) => {
                                                                return { ...pre, currentPasswordType: passwordType }
                                                            })
                                                        }}></i></button>
                                                    </div>
                                                    {state.formDataError.profileStep1.oldpassword.map((val, index) => {
                                                        return (<>
                                                            <p className='form_error'>{val}</p>
                                                        </>)
                                                    })}
                                                    {/* <div className="op-0 pwd-power d-flex justify-content-between">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="post-field-password position-relative">
                                                    <label htmlhtmlFor="settingsNewPwd" className="form-label">New Password</label>
                                                    <div className="position-relative">
                                                        <input type={state.newPasswordType} className="form-control" id="settingsNewPwd" placeholder="Enter New Password" value={state.formData.profileStep1.newpassword} name="newpassword" onChange={onHandler} />

                                                        <button className="btn p-0 pwd-btn" type="button"><i className="fas fa-eye-slash" onClick={() => {
                                                            let passwordType = "password";
                                                            if (state.newPasswordType === "password") {
                                                                passwordType = "text"
                                                            }
                                                            setState((pre) => {
                                                                return { ...pre, newPasswordType: passwordType }
                                                            })
                                                        }}></i></button>
                                                    </div>
                                                    {state.formDataError.profileStep1.newpassword.map((val, index) => {
                                                        return (<>
                                                            <p className='form_error'>{val}</p>
                                                        </>)
                                                    })}
                                                    <div className="pwd-power d-flex justify-content-between">
                                                        <span style={state.passwordStrength.veryWeak}></span>
                                                        <span style={state.passwordStrength.weak}></span>
                                                        <span style={state.passwordStrength.strong}></span>
                                                        <span style={state.passwordStrength.veryStrong}></span>
                                                    </div>
                                                </div>
                                            </div>
                                            </>

                                            }

                                      {!userAuth.user.ispassword &&  <div className="col-md-12 mb-4">
                                              
                                                    <button type='button' className='btn site-btn'  disabled={state.buttonDisabled} onClick={sendEmailForPasswordChange}><span>Request for generate Password{(state.buttonLoader) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span></button>
                                                
                                            </div>}
                                            <div className="col-md-6">
                                                <div className="btn-container">
                                                    <button type='submit' className='btn site-btn w-100' disabled={state.buttonDisabled}><span>Save Settings{(state.buttonLoader) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span></button>
                                                </div>
                                            </div>
                                            {/* Delete Acciount */}
                                            <div className="col-md-6 d-flex align-items-center justify-content-end">
                                                <div className="btn-container text-center text-md-end mt-4 mt-md-0">
                                                    <button className='btn btn-delete shadow-none lh-1' type="button" data-bs-toggle="modal" data-bs-target="#deleteAcc"><span><i className='fas fa-trash'></i> Delete Account</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className={`tab-pane fade show active ${state.profileTab.profileStep2 ? '' : 'd-none'}`} id="profileNoti_Tab" role="tabpanel" aria-labelledby="profileNoti_Btn">
                                <div className="tab-wrapper">
                                    <form onSubmit={onSubmit}>
                                        <div className="post-field-toggleBtn mb-0">
                                            <div className="form-check form-switch ps-0 d-flex justify-content-between align-items-center">
                                                <label className="form-check-label w-100 mb-0 ms-0" htmlhtmlFor="userFollowNoti">User Follows You</label>
                                                <input className="form-check-input" type="checkbox" role="switch" name="userFollow" id="userFollowNoti" defaultChecked={state.formData.profileStep2.notificationSettings.userFollow} onChange={notificationToggle} />
                                            </div>
                                        </div>
                                        <div className="post-field-toggleBtn mb-0">
                                            <div className="form-check form-switch ps-0 d-flex justify-content-between align-items-center">
                                                <label className="form-check-label w-100 mb-0 ms-0" htmlhtmlFor="userSaveImgNoti">User saves your image to their image collection</label>
                                                <input className="form-check-input" type="checkbox" role="switch" name="userSaveImageCollection" id="userSaveImgNoti" defaultChecked={state.formData.profileStep2.notificationSettings.userSaveImageCollection} onChange={notificationToggle} />
                                            </div>
                                        </div>
                                        <div className="post-field-toggleBtn mb-0 border-0">
                                            <div className="form-check form-switch ps-0 d-flex justify-content-between align-items-center">
                                                <label className="form-check-label w-100 mb-0 ms-0" htmlhtmlFor="userLikeNoti">User likes one of your image</label>
                                                <input className="form-check-input" type="checkbox" name="likeImage" role="switch" id="userLikeNoti" defaultChecked={state.formData.profileStep2.notificationSettings.likeImage} onChange={notificationToggle} />
                                            </div>
                                        </div>
                                        {/* Save Settings */}
                                        <div className="col-md-6 mt-3">
                                            <div className="btn-container">
                                                <button type='submit' className='btn site-btn w-100' disabled={state.buttonDisabled}><span>Save Settings{(state.buttonLoader) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <form onSubmit={onSubmit}>
                                <div className={`tab-pane fade show active ${state.profileTab.profileStep3 ? '' : 'd-none'}`} id="profileInterest_Tab" role="tabpanel" aria-labelledby="profileInterest_Btn">
                                    <div className="tab-wrapper">

                                        {(Object.keys(state.interests)).length > 0 ?
                                            state.interests.map((val) => {
                                                return (<>
                                                    <div className="profile_interest_row" key={val.id}>
                                                        <h3>{val.name}</h3>
                                                        <div className="row">

                                                            {(Object.keys(val.subCategories)).length > 0 ?

                                                                val.subCategories.map((val2) => {
                                                                    return (
                                                                        <>
                                                                            <div className="col-3">
                                                                                <FilterItem
                                                                                    key={val2._id}

                                                                                    data={val2}
                                                                                    checked={(state.formData.profileStep3.userIntersts.length > 0 && state.formData.profileStep3.userIntersts.includes(val2._id)) ? true : false}
                                                                                    state={[state, setState]}
                                                                                    tabIndex={3}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })

                                                                : ""



                                                            }



                                                        </div>
                                                    </div>
                                                </>)
                                            })
                                            : <>
                                                <p>there is no category yet, Please skip this step</p>
                                            </>}
                                        <div className="col-md-6 mt-3">
                                            <div className="btn-container">
                                                <button type='submit' className='btn site-btn w-100' disabled={state.buttonDisabled}><span>Save Settings{(state.buttonLoader) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className={`tab-pane fade show active ${state.profileTab.profileStep4 ? '' : 'd-none'}`} id="profilePrivacy_Tab" role="tabpanel" aria-labelledby="profilePrivacy_Btn">
                                <div className="tab-wrapper">
                                    <form>
                                        <div className="post-field-toggleBtn mb-0">
                                            <div className="form-check form-switch ps-0 d-flex justify-content-between align-items-center">
                                                <label className="form-check-label w-100 mb-0 ms-0" htmlhtmlFor="userFollowNoti2">User Follows You</label>
                                                <input className="form-check-input" type="checkbox" role="switch" id="userFollowNoti2" defaultChecked={true} />
                                            </div>
                                        </div>
                                        <div className="post-field-toggleBtn mb-0">
                                            <div className="form-check form-switch ps-0 d-flex justify-content-between align-items-center">
                                                <label className="form-check-label w-100 mb-0 ms-0" htmlhtmlFor="userSaveImgNoti2">User saves your image to their image collection</label>
                                                <input className="form-check-input" type="checkbox" role="switch" id="userSaveImgNoti2" />
                                            </div>
                                        </div>
                                        <div className="post-field-toggleBtn mb-0 border-0">
                                            <div className="form-check form-switch ps-0 d-flex justify-content-between align-items-center">
                                                <label className="form-check-label w-100 mb-0 ms-0" htmlhtmlFor="userLikeNoti2">User likes one of your image</label>
                                                <input className="form-check-input" type="checkbox" role="switch" id="userLikeNoti2" />
                                            </div>
                                        </div>
                                        {/* Save Settings */}
                                        <div className="col-md-6 mt-3">
                                            <div className="btn-container">
                                                <button type='submit' className='btn site-btn w-100'><span>Save Settings</span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="modal fade" id="changeEmail" tabIndex="-1" aria-labelledby="changeEmailLabel" aria-hidden="true">
            <form onSubmit={onModalSubmit}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center border-0">
                            <h5 className="modal-title" id="changeEmailLabel">Change Your Email</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pb-0">
                            {/* Collection title Field */}
                            <div className="post-field-title">
                                <label htmlFor="currEmail">new Email</label>
                                <input type="email" name="email" id="currEmail" value={state.modalData.changeEmailData.email} className="form-control" onChange={modalHandler} placeholder="johnsmiths@websitename.com" required={true} />
                                {state.modalData.changeEmailData.error.email.map((val, index) => {
                                    return (<>
                                        <p className='form_error'>{val}</p>
                                    </>)
                                })}
                            </div>
                            {/* Collection title Field */}
                            {(userAuth.user.ispassword) ?
                                <div className="post-field-title">
                                    <div className="mb-3 password position-relative">
                                        <label htmlFor="changeEmailPwd">Enter Your Password</label>
                                        <div className="position-relative">
                                            <input type={state.modalData.passwordType} value={state.modalData.changeEmailData.password} className="form-control" id="changeEmailPwd" name='password' onChange={modalHandler} placeholder="**********" required={true} />
                                            {state.modalData.changeEmailData.error.password.map((val, index) => {
                                                return (<>
                                                    <p className='form_error'>{val}</p>
                                                </>)
                                            })}
                                            <button className="btn p-0 pwd-btn" type='button'><i className="fas fa-eye-slash" onClick={() => {
                                                let passwordType = "password";
                                                if (state.modalData.passwordType === "password") {
                                                    passwordType = "text"
                                                }

                                                state.modalData.passwordType = passwordType;

                                                //let newState={...state,}
                                                setState((pre) => {
                                                    return { ...pre, ...state }
                                                })
                                            }}></i></button>
                                            <div className="pwd-power d-flex justify-content-between">
                                                <span style={state.modalData.changeEmailData.passwordStrength.veryWeak}></span>
                                                <span style={state.modalData.changeEmailData.passwordStrength.weak}></span>
                                                <span style={state.modalData.changeEmailData.passwordStrength.strong}></span>
                                                <span style={state.modalData.changeEmailData.passwordStrength.veryStrong}></span>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className="post-field-title">
                                    <div className="mb-3 password position-relative">
                                        <p style={{ color: "red" }}><b>Note:</b>You are Social Login user, for change email you should set your password</p>
                                        <label htmlFor="changeEmailPwd2">Enter new Password</label>
                                        <div className="position-relative">
                                            <input type="password" value={state.modalData.changeEmailData.password} className="form-control" id="changeEmailPwd2" name='password' onChange={onHandler} placeholder="**********" required={true} />
                                            {state.modalData.changeEmailData.error.password.map((val, index) => {
                                                return (<>
                                                    <p className='form_error'>{val}</p>
                                                </>)
                                            })}
                                            <button className="btn p-0 pwd-btn" type='button'><i className="fas fa-eye-slash" ></i></button>
                                            <div className="pwd-power d-flex justify-content-between">
                                                <span style={state.modalData.changeEmailData.passwordStrength.veryWeak}></span>
                                                <span style={state.modalData.changeEmailData.passwordStrength.weak}></span>
                                                <span style={state.modalData.changeEmailData.passwordStrength.strong}></span>
                                                <span style={state.modalData.changeEmailData.passwordStrength.veryStrong}></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                        <div className="modal-footer border-0 pt-0 justify-content-center">
                            <button className='btn site-btn' type='submit' style={{ width: '100%', padding: '12px 12px' }} disabled={state.buttonDisabled}>
                                <span>Change Email {(state.buttonLoader) ? <div className="spinner-border" style={{ width: "1rem", height: "1rem" }}></div> : ""}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </>);
}

export default React.memo(ProfileSettings);