import { Storage } from "../helpers";
import { axios } from "./config"
export class Resource extends axios {
    axios;
    token;
    prefix;
    constructor() {
        super();
        this.axios = this.axiosConfig();
        this.token = Storage.getCookie(`${process.env.NODE_ENV}-userToken`);
        this.prefix = "/resource/"
    }
    async createCollection(data) {
        try {
            let formData = new FormData();
            if (Object.keys(data).length > 0) {
                for (let key in data) {
                    if (key === "img_url") {
                        if (data[key] && data[key] !== "") {
                            formData.append(key, data[key]);
                        }
                    } else {
                        formData.append(key, data[key]);
                    }

                }
                let result = await this.axios({
                    method: "post",
                    url: this.prefix.concat(""),
                    data: formData,
                    headers: {
                        Authorization: this.token
                    }
                });
                return new Promise((res, rej) => {
                    res(result.data);
                })
            }

        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async getResourceByPattern(name = "", userID) {
        try {
            let result = await this.axios({
                method: "get",
                url: this.prefix.concat(`getByPattern/${name}/${userID}`),
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async createResourceLibarary(data) {
        try {

            if (Object.keys(data).length > 0) {
                let result = await this.axios({
                    method: "post",
                    url: this.prefix.concat("createItems"),
                    data: data,
                    headers: {
                        Authorization: this.token
                    }
                });
                return new Promise((res, rej) => {
                    res(result.data);
                })
            }

        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async getResource(id) {
        try {
            let result = await this.axios({
                method: "get",
                url: this.prefix.concat(`getResource/${id}`),
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async getResourceItem(id) {
        try {
            let result = await this.axios({
                method: "get",
                url: this.prefix.concat(`getResourceItem/${id}`),
                headers: {
                    Authorization: this.token
                }
            });
            return new Promise((res, rej) => {
                res(result.data);
            })


        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }
    async toggleFollow(id) {
        try {
                    let result = await this.axios({
                    method: "post",
                    url: this.prefix.concat("followToggle"),
                    data: {resource_id:id},
                    headers: {
                        Authorization: this.token
                    }
                });
                return new Promise((res, rej) => {
                    res(result.data);
                })
            

        } catch (e) {
            return new Promise((res, rej) => {
                rej(e);
            })
        }
    }

}


