import {Outlet,Navigate} from "react-router-dom";
import { Storage } from "../helpers";
export const RestrictedRoutes = () => {
let getCookies=Storage.getCookie(`${process.env.NODE_ENV}-userToken`);    
if(!getCookies){
    return <Outlet/>;
}
else{
   return <Navigate to="/"/>
}

}
