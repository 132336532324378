import React from 'react';
import Header from './Header';

const TermsCondition = () => {
    return (<>
        
        <section className="terms_policy">
            <div className="container">
                <h2 className="title">Terms of Services</h2>
                <div className="content">
                    <p>The Terms of Services was last updated on the 23rd April, 2020 which is the date these Terms of Services became effective.</p>
                    <p>The Terms of Service, with our Privacy Policy, form the full and exclusive legal agreement between you and Your Growth Stop and has the same effect as if you had physically signed an agreement on physical paper.</p>
                    <p>The Terms of Services includes all terms listed in this document.</p>
                    <p>The Your Growth Stop Website/Network and Mobile Application are owned and operated by Your Growth Stop Limited (“Your Growth Stop”, “us”, “we” or “our”). By accessing and using the services which are available through the Your Growth Stop Website/Network or Mobile applications, you agree to these Terms of Services and the Your Growth Stop Privacy Policy which you can be found via the following link ….</p>
                    <p>The Terms of Services (‘Terms’’) governs our rights and your rights with regards to any actions by you or other users in connection with the Your Growth Stop services. We Recommend that you read these Terms in detail;
                        <br />
                        The Terms and Services are governed by the New Zealand Law.
                    </p>
                    <h3>Terms of Services – (Contacting US)</h3>
                    <ol>
                        <li>We are always open to feedback, suggestions and opinions. All feedback, suggestions and opinions that you do submit us will automatically become our property, legal owned by us and therefore we may use and publish any feedback, suggestions and opinions at our discretion without any remuneration or attribution to you.</li>
                        <li>When contacting us for any reason other than making a claim which is related to the Terms of Services, we cannot guarantee a reply in any specific time frame.</li>
                    </ol>
                    <h3>Terms of Services – (General)</h3>
                    <ol>
                        <li>No partnership, join venture, agency or employment is created as a result of agreeing to the Terms of Services or using our Website/Network or Mobile Application.</li>
                        <li>You have no authority whatsoever to bind us in any regard whatsoever as a result of agreeing to the Terms of Services or using our Website/Network or Mobile Application.</li>
                        <li>You must be 18 years old or older or have your parent or guardian’s consent to agree to the Terms of Services and use our Website/Network or Mobile Application. Parents or
                            guardians have the right to request the deletion of an account which is used by any individual which is under the age of 18. Parents or guardians can contact us with such a request
                            via our contact form or the following email: contact@Your Growth Stop.com</li>
                        <li>You must have the authority and power to agree to the Terms of Services, use our Website/Network or Mobile Application and enter a legal binding agreement/contract with us and
                            not be barred from doing so under any applicable law. Please note: If you do not agree to the Terms of Services, you are NOT permitted/allowed to use our Website/Network or
                            Mobile Application. If you have any questions regarding our Terms of Services, you can contact as via the following email: contact@Your Growth Stop.com</li>
                        <li>Your Growth Stop can at any time, at our sole discretion, modify or revise the Terms of Services which will be published on our Website/Network and Mobile Application, and you agree
                            to be bound by such modifications/changes. It is your responsibility to regular review the Terms of Services and Privacy Policies in order to be up to date with any changes to the
                            Terms or Services and Privacy Policies. Any changes made to the Terms of Services and/or Privacy Policy will be effective immediately when we post them and shall apply to all
                            access to and use of our Website/Network or Mobile from the moment we post them.</li>
                        <li>You are solely responsible for your use of our Services; this includes any content you post to the Your Growth Stop Website/Network or Mobile Application using the Services therefore
                            you are solely responsible for any consequences which may arise from the content which you post.</li>
                        <li>Part of our services is allowing you to add inspiring and education content related to business, careers and personal development to the Your Growth Stop Website/Network and Mobile Application. You can add such content by either:</li>
                        <ul>
                            <li>Uploading images, videos directly from your own personal computer/device to our Website/Network or Mobile Application;</li>
                            <li>Sharing images, videos or blogs from other websites;</li>
                            <li>Writing/adding a blog/article to the Website/Network or Mobile Application.</li>
                        </ul>
                        <li>Any content which you post to the Your Growth Stop Website/Network or Mobile Application can be viewed by other users and visitors of the Website/Network or Mobile Application.
                            Please note: Users can add any item which you posted to their own collection, which will automatically make that content part of that user’s profile, even if you later decide to remove
                            the content from your own collection.</li>
                        <li>You acknowledge that our Website/Network and Mobile Application also includes Advertising to users and visitors of the Your Growth Stop Website/Network and Mobile Application. You
                            agree that we and any of our third-party providers and partners can advertise through the Your Growth Stop Website/Network or Mobile Application. You also agree that we are allowed
                            to use any content which you post on the Your Growth Stop Website/Network and Mobile Application for our own advertising, without any payment to you for such advertisement or
                            without any notification to you.</li>
                        <li>The Services which we provide are subjected to change, and at any time we reserve the right to add/ modify or remove any services without any prior notice to you.</li>
                        <li>We hold the absolute right to create limits on use and storage of our Website/Network or Mobile Application at our sole discretion at any time without any prior notice to you.</li>
                        <li>You are solely responsible for any interactions which you are a part of with other users of our Services this includes online and offline.</li>
                        <li>Although we are not obliged to, we reserve the right to monitor and become involved in any issues between you and other users of our Services.</li>
                        <li>You must be 18 years old or older or have your parent or guardian’s consent to agree to the Terms of Services and use our Website/Network or Mobile Application. Parents or
                            guardians have the right to request the deletion of an account which is used by any individual which is under the age of 18. Parents or guardians can contact us with such a request
                            via our contact form or the following email: contact@Your Growth Stop.com</li>
                        <li>You must have the authority and power to agree to the Terms of Services, use our Website/Network or Mobile Application and enter a legal binding agreement/contract with us and
                            not be barred from doing so under any applicable law. Please note: If you do not agree to the Terms of Services, you are NOT permitted/allowed to use our Website/Network or
                            Mobile Application. If you have any questions regarding our Terms of Services, you can contact as via the following email: contact@Your Growth Stop.com</li>
                        <li>Your Growth Stop can at any time, at our sole discretion, modify or revise the Terms of Services which will be published on our Website/Network and Mobile Application, and you agree
                            to be bound by such modifications/changes. It is your responsibility to regular review the Terms of Services and Privacy Policies in order to be up to date with any changes to the
                            Terms or Services and Privacy Policies. Any changes made to the Terms of Services and/or Privacy Policy will be effective immediately when we post them and shall apply to all
                            access to and use of our Website/Network or Mobile from the moment we post them.</li>
                        <li>You are solely responsible for your use of our Services; this includes any content you post to the Your Growth Stop Website/Network or Mobile Application using the Services therefore
                            you are solely responsible for any consequences which may arise from the content which you post.</li>
                        <li>Part of our services is allowing you to add inspiring and education content related to business, careers and personal development to the Your Growth Stop Website/Network and Mobile Application. You can add such content by either:</li>
                        <li>Any content which you post to the Your Growth Stop Website/Network or Mobile Application can be viewed by other users and visitors of the Website/Network or Mobile Application.
                            Please note: Users can add any item which you posted to their own collection, which will automatically make that content part of that user’s profile, even if you later decide to remove
                            the content from your own collection.</li>
                        <li>You acknowledge that our Website/Network and Mobile Application also includes Advertising to users and visitors of the Your Growth Stop Website/Network and Mobile Application. You
                            agree that we and any of our third-party providers and partners can advertise through the Your Growth Stop Website/Network or Mobile Application. You also agree that we are allowed
                            to use any content which you post on the Your Growth Stop Website/Network and Mobile Application for our own advertising, without any payment to you for such advertisement or
                            without any notification to you.</li>
                        <li>The Services which we provide are subjected to change, and at any time we reserve the right to add/ modify or remove any services without any prior notice to you.</li>
                        <li>We hold the absolute right to create limits on use and storage of our Website/Network or Mobile Application at our sole discretion at any time without any prior notice to you.</li>
                        <li>You are solely responsible for any interactions which you are a part of with other users of our Services this includes online and offline.</li>
                        <li>Although we are not obliged to, we reserve the right to monitor and become involved in any issues between you and other users of our Services.</li>
                        <li>You must be 18 years old or older or have your parent or guardian’s consent to agree to the Terms of Services and use our Website/Network or Mobile Application. Parents or
                            guardians have the right to request the deletion of an account which is used by any individual which is under the age of 18. Parents or guardians can contact us with such a request
                            via our contact form or the following email: contact@Your Growth Stop.com</li>
                        <li>You must have the authority and power to agree to the Terms of Services, use our Website/Network or Mobile Application and enter a legal binding agreement/contract with us and
                            not be barred from doing so under any applicable law. Please note: If you do not agree to the Terms of Services, you are NOT permitted/allowed to use our Website/Network or
                            Mobile Application. If you have any questions regarding our Terms of Services, you can contact as via the following email: contact@Your Growth Stop.com</li>
                        <li>Your Growth Stop can at any time, at our sole discretion, modify or revise the Terms of Services which will be published on our Website/Network and Mobile Application, and you agree
                            to be bound by such modifications/changes. It is your responsibility to regular review the Terms of Services and Privacy Policies in order to be up to date with any changes to the
                            Terms or Services and Privacy Policies. Any changes made to the Terms of Services and/or Privacy Policy will be effective immediately when we post them and shall apply to all
                            access to and use of our Website/Network or Mobile from the moment we post them.</li>
                        <li>You are solely responsible for your use of our Services; this includes any content you post to the Your Growth Stop Website/Network or Mobile Application using the Services therefore
                            you are solely responsible for any consequences which may arise from the content which you post.</li>
                        <li>Part of our services is allowing you to add inspiring and education content related to business, careers and personal development to the Your Growth Stop Website/Network and Mobile Application. You can add such content by either:</li>
                        <li>Any content which you post to the Your Growth Stop Website/Network or Mobile Application can be viewed by other users and visitors of the Website/Network or Mobile Application.
                            Please note: Users can add any item which you posted to their own collection, which will automatically make that content part of that user’s profile, even if you later decide to remove
                            the content from your own collection.</li>
                        <li>You acknowledge that our Website/Network and Mobile Application also includes Advertising to users and visitors of the Your Growth Stop Website/Network and Mobile Application. You
                            agree that we and any of our third-party providers and partners can advertise through the Your Growth Stop Website/Network or Mobile Application. You also agree that we are allowed
                            to use any content which you post on the Your Growth Stop Website/Network and Mobile Application for our own advertising, without any payment to you for such advertisement or
                            without any notification to you.</li>
                        <li>The Services which we provide are subjected to change, and at any time we reserve the right to add/ modify or remove any services without any prior notice to you.</li>
                        <li>We hold the absolute right to create limits on use and storage of our Website/Network or Mobile Application at our sole discretion at any time without any prior notice to you.</li>
                        <li>You are solely responsible for any interactions which you are a part of with other users of our Services this includes online and offline.</li>
                        <li>Although we are not obliged to, we reserve the right to monitor and become involved in any issues between you and other users of our Services.</li>
                    </ol>
                </div>
            </div>
        </section>
    </>);
}

export default TermsCondition;