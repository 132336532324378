import React from 'react';

const UploaderField = (props) => {
    return (<>
        <div className="col-auto uploaderField">
            <input type="file" name={props.name} onChange={props.fun} disabled={props.disabled} accept={props.acceptAttr} />
            <label htmlFor="postMediaUploader">
                <img src="assets/images/addImgIcon.svg" alt="Upload Img Icon" />
                <p>Upload from Storage</p>
            </label>
            {props.errors !=="" && <p style={{color:"red"}}>{props.errors}</p>}
        </div>
    </>);
}

export default UploaderField;